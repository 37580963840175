import {
    Alert,
    Box,
    Button as ChakraButton,
    Flex,
    Heading,
    Icon,
    Spinner,
    StackDivider,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AiFillCreditCard, AiFillPrinter} from "react-icons/ai";
import Button from "../Button";
import InsuranceForm from "../../components/forms/InsuranceForm";
import {withProductsRequired, withSetUserRequired, withUserRequired} from "../../config/withs";
import {fetchSubscriptionAttestation} from "../../services/documents";
import {hasActiveSubscription} from "../../services/subscriptions";
import {generateParameters, generateSignature} from "../../services/systempay";
import {createInsurance} from "../../services/user";


export function Insurances({products, user, setUser}: InsurancesProps) {
    const {t, i18n} = useTranslation('profile')
    const toast = useToast()
    const product = products.find(({name}) => name.toLowerCase().includes("assurance"))
    const insurances = user.subscriptions?.filter(
        ({product: {id}}) => product?.id === id
    )

    if (!product) return <Spinner color={"primary"} />

    const parameters = generateParameters(user, product)
    const systempaySignature = generateSignature(process.env['REACT_APP_SYSTEMPAY_SELLER_CERTIFICATE'] as string, parameters)
    const handleInsuranceSubmit = async (body: any) => {
        try {
            const resp = await createInsurance(body);
            setUser({...user, insurance: resp})
        } catch (_) {
            toast({description: t('profile_toast_insurance_error'), isClosable: true, status: "error"})
        }
    }

    return (
        <VStack w={'100%'} spacing={8}>
            <Heading fontSize={'md'} textColor={'primary'}>{t('profile_insurance_heading')}</Heading>
            {insurances?.length > 0 && <TableContainer w={'100%'}>
                <Table size={'sm'} variant={'simple'}>
                    <Thead>
                        <Tr>
                            <Th>{t('profile_subscription_table_head_created_at')}</Th>
                            <Th>{t('profile_subscription_table_head_payment_mean')}</Th>
                            <Th>{t('profile_subscription_table_head_start_date')}</Th>
                            <Th>{t('profile_subscription_table_head_end_date')}</Th>
                            <Th>{t('profile_subscription_table_head_prints')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {insurances.sort(
                            (s1, s2) => s1.createdAt >= s2.createdAt ? 1 : -1
                        ).map(({
                            endDate,
                            id,
                            paymentMean,
                            startDate,
                            createdAt
                        }) => {
                            const handleDownload = async () => {
                                const toastId = toast({
                                    status: "loading",
                                    description: t('profile_toast_loading_document_title')
                                })
                                const file = await fetchSubscriptionAttestation(id);
                                if (!file) {
                                    toast.update(toastId, {
                                        status: "error",
                                        description: t('profile_toast_error_no_document'),
                                        isClosable: true
                                    })
                                } else {
                                    toast.close(toastId)
                                    const aElement = document.createElement('a');
                                        aElement.setAttribute(
                                            'download',
                                            `${t("profile_document_attestation")} ${user?.firstName} ${user?.lastName}.pdf`
                                        )
                                        aElement.href = file;
                                        aElement.setAttribute('target', '_blank');
                                        aElement.click();
                                        URL.revokeObjectURL(file);
                                }
                            }
                            return (
                                <Tr key={`insurance-${id}`}>
                                    <Td>{new Date(createdAt).toLocaleDateString(i18n.language)}</Td>
                                    <Td>{paymentMean}</Td>
                                    <Td>{startDate ? new Date(startDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                    <Td>{endDate ? new Date(endDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                    <Td>
                                        <ChakraButton onClick={handleDownload} variant={'unstyled'}>
                                            <Flex align={'center'} textColor={'primary'}>
                                                <Icon as={AiFillPrinter} mr={1}/>
                                                <Text _hover={{textDecoration: 'underline'}}>
                                                    {t('profile_insurance_table_prints_link')}
                                                </Text>
                                            </Flex>
                                        </ChakraButton>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>}
            <VStack w={'100%'} spacing={8} divider={<StackDivider />}>
                <Box border={'1px'} borderColor={'gray.200'} p={4} rounded={'sm'} w={'100%'} fontSize={'xs'}>
                    <Text as={'span'}>{t('profile_insurance_conditions_warranty') + ' '}</Text>
                    <Text as={'span'} textColor={'primary'} _hover={{textDecoration: 'underline'}}>
                        <a href={'https://www.chambre-syndicale-sophrologie.fr/assurance-responsabilite-civile/'} target={'_blank'} rel={"noreferrer"}>
                            {t('profile_insurance_warranty_download_link')}
                        </a>
                    </Text>
                    <Text as={'span'}>. {t('profile_insurance_more_details') + ' '}</Text>
                    <Text as={'span'} textColor={'primary'} _hover={{textDecoration: 'underline'}}>
                        <a href={'mailto:agence.marieclerc@axa.fr'}>
                            {t('profile_insurance_more_details_email')}
                        </a>
                    </Text>.
                </Box>
                {user.siret ? hasActiveSubscription(user) ? <VStack w={'100%'} divider={<StackDivider/>} spacing={8}>
                    {user?.insurance && <VStack align={'start'} direction={'column'} w={'100%'}>
                        <Text textColor={'primary'} w={'100%'}>
                            {t('profile_insurance_price', {amount: product?.price})}
                        </Text>
                        <VStack border={'1px'} borderColor={'gray.200'} p={4} rounded={'sm'} spacing={4}>
                            <Text as={'span'}>{t('profile_subscription_title')}</Text>
                            <Box w={'100%'}>
                                <Text fontSize={'xs'}>- {t('profile_subscription_instant_transaction')}</Text>
                                <Text fontSize={'xs'}>- {t('profile_subscription_secured_transaction')}</Text>
                            </Box>
                            <form method={'POST'} action={'https://paiement.systempay.fr/vads-payment/'}>
                                {Object.keys(parameters).map((key) => (
                                    <input key={`sp-${key}`} type={"hidden"} name={key}
                                           value={(parameters as any)[key]}/>
                                ))}
                                <input type={"hidden"} name={"signature"} value={systempaySignature}/>
                                <Button leftIcon={<AiFillCreditCard/>} type={"submit"}>
                                    {t('profile_button_pay_insurance')}
                                </Button>
                            </form>
                        </VStack>
                    </VStack>}
                    <Box w={'100%'}><InsuranceForm onSubmit={handleInsuranceSubmit} user={user}/></Box>
                </VStack> : <Alert status={"error"}>{t('profile_insurance_no_subscription')}</Alert>
                    : <Alert status={"error"}>{t('profile_insurance_no_siret')}</Alert>}
            </VStack>
        </VStack>
    )
}

export interface InsurancesProps extends withProductsRequired, withUserRequired, withSetUserRequired {}

export default Insurances