import {Button as ChakraButton, HStack, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {ChangeEvent, useState} from "react";
import {asForm} from "../../config/as";
import {withErrors, withErrorsRequired, withUserRequired} from "../../config/withs";
import Button from "../Button";
import {PasswordField } from "../fields";
import {validateEmail, validatePassword} from "../../utils/validators";
import {addError, removeError} from "../../utils/errors";
import PasswordFieldChange from "../fields/PasswordFieldChange";

export function EditPasswordForm({errors = {}, onSubmit = () => {}, user}: EditPasswordFormProps) {
    const {t} = useTranslation('common')
    const [state, setState] = useState<EditPasswordFormState>({password: "", password2: "", errors: {}})

    const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validatePassword(e.target.value)) {
            ajouterErreur( 'password', '8 caractères minimum')
        } else {
            supprimerErreur('password')
            if (state.password2 !=null && e.target.value !=state.password2 ){
                 ajouterErreur( 'password2', 'Mot de passe différent')
            }else{
                supprimerErreur('password2')
            }
        }
        setState({...state, password: e.target.value})
    }
    const handlePassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.password) {
            ajouterErreur( 'password2', 'Mot de passe différent')
        } else {
            supprimerErreur('password2')
        }
        setState({...state, password2: e.target.value})
    }

    const controlToutEtEnvoi =() => {
        let champsObligatoire = false
        if (state.password == null){
            ajouterErreur( 'password', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.password2 == null){
            ajouterErreur( 'password2', 'Champs obligatoire')
            champsObligatoire = true
        }
        if(champsObligatoire)
            return

        let retour = true

        if (retour && state.password != null && validatePassword(state.password)){
            supprimerErreur('password')
            retour = true
        } else  {
            ajouterErreur( 'password', 'password invalide')
            return
        }
        if (retour && state.password2 != null && (state.password2 === state.password)){
            supprimerErreur('password2')
            retour = true
        } else  {
            ajouterErreur( 'password2', 'password différent')
            return
        }

        if (retour){
            handleSubmit()
        }

    }
    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: state.errors[str][0], show: true}
        if (Object.keys(errors).includes(str)) return {content: errors[str][0], show: true}
        return undefined
    };

    const isDisabled = (Object.keys(state.errors).length !== 0
        || state.password !== state.password2 || !validatePassword(state.password))

    const handleSubmit = () => {onSubmit({password: state.password})}
    const handleCancel = () => {setState({password: "", password2: "", errors: {}})}

    return (
        <VStack w={'100%'} spacing={6}>
            <PasswordFieldChange label={t('field_new_password_label')} value={state.password} onChange={handlePasswordChange} required error={getError('password')} />
            <PasswordFieldChange label={t('field_confirm_password_label')} value={state.password2} onChange={handlePassword2Change} required error={getError('password2')} />
            <HStack w={'100%'}>
                <ChakraButton colorScheme={'red'} fontWeight={'normal'} size={'sm'} onClick={handleCancel}>
                    {t('button_cancel_label')}
                </ChakraButton>
                <Button
                    isDisabled={isDisabled}
                    onClick={controlToutEtEnvoi}
                >
                    {t('button_save_label')}
                </Button>
            </HStack>
        </VStack>
    )
}

export interface EditPasswordFormState extends withErrorsRequired {
    password: string;
    password2: string;
}

export interface EditPasswordFormProps extends asForm, withUserRequired, withErrors {}

export default EditPasswordForm