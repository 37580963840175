import * as React from "react";
import {asField} from "../../config/as";
import IconField from "../IconField";

export function ApeCodeField({onChange = () => {}, ...props}: ApeCodeFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = String(e.target.value)
        const regexp = new RegExp('^[a-zA-Z]$')
        if (value.length > 5)
            value = value.substring(0, 5)
        if (value.length < 5 && Number.isNaN(Number(value[value.length - 1])))
            value = value.substring(0, value.length - 1)
        if (value.length === 5 && !regexp.test(value[value.length - 1]))
            value = value.substring(0, value.length - 1)
        onChange({target: {value: value.toUpperCase()}})
    }
    return <IconField icon={'building'} onChange={handleChange} {...props} />
}

export interface ApeCodeFieldProps extends asField {}

export default ApeCodeField