export async function authenticatedRequest({url, fetchProps}: requestProps) {
    const token = window.localStorage.getItem(process.env['REACT_APP_SESSION_COOKIE'] as string)
    if (!token) throw Error('error_user_not_logged_in')
    return await request({
        url,
        fetchProps: {
            ...fetchProps,
            headers: {...fetchProps?.headers, 'Authorization': `Token ${token}`}
        }}
    )
}

export async function request({url, fetchProps}: requestProps) {
    const response = await fetch(url, fetchProps);
    if (response.status === 503) {
        window.location.assign('/maintenance')
    }
    return response
}

export interface requestProps {
    url: string;
    fetchProps?: RequestInit;
}

export default authenticatedRequest;