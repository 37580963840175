import {ChangeEvent, useState} from "react";
import {withInitialState, withOnSubmit} from "../../config/withs";
import {
    CityField,
    DateField,
    EmailField,
    NameField,
    NirppField,
    OptionalField,
    PasswordField,
    PhoneField,
    SchoolField,
    SexField,
    LocationField,
    ZipCodeField,
} from "../fields";
import {Alert, AlertIcon, Box, Container, Divider, Flex, Grid, GridItem, VStack} from "@chakra-ui/react";
import Button from '../Button';
import {useTranslation} from "react-i18next";
import {addError} from "../../utils/errors";
import {
    validateAlpha,
    validateDateOfBirth,
    validateEmail,
    validateName,
    validateNirpp,
    validateNotEmpty,
    validatePassword,
    validatePhoneNumber,
    validateSex,
    validateZipcode,
} from "../../utils/validators";


function validate(name: string, value: string) {
    switch (name) {
        case 'sex' : return validateSex(value)
        case 'firstName': return validateNotEmpty(value) && validateName(value)
        case 'lastName': return validateNotEmpty(value) && validateName(value)
        case 'mobilePhoneNumber': return validateNotEmpty(value) && validatePhoneNumber(value)
        case 'dateOfBirth': return validateNotEmpty(value) && validateDateOfBirth(value)
        case 'nirpp': return validateNotEmpty(value) && validateNirpp(value)
        case 'addressLine1': return validateNotEmpty(value)
        case 'city': return validateNotEmpty(value) && validateAlpha(value)
        case 'zipCode': return validateNotEmpty(value) && validateZipcode(value)
        case 'previousSchoolId': return validateNotEmpty(value)
        default: return true
    }
}

export function RegisterForm({errors = {}, initialState = {}, onSubmit = () => {}}: RegisterFormProps) {
    const { t } = useTranslation('common')
    const {t: authT} = useTranslation('auth')
    const [state, setState] = useState<RegisterFormState>({body: initialState, errors: {}});
    
    const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validatePassword(e.target.value)) {
            ajouterErreur( 'password', '8 caractères minimum')
        } else {
            supprimerErreur('password')
            if (state.body.password2 !=null && e.target.value !=state.body.password2 ){
                 ajouterErreur( 'password2', 'Mot de passe différent')
            }else{
                supprimerErreur('password2')
            }
        }
        setState({...state, body: {...state.body, ['password']: e.target.value}})
    }
    const handlePassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.body.password) {
            ajouterErreur( 'password2', 'Mot de passe différent')
        } else {
            supprimerErreur('password2')
        }
        setState({...state, body: {...state.body, ['password2']: e.target.value}})
    }
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validateEmail(e.target.value)) {
            ajouterErreur( 'email', 'Email invalide')
        } else {
            supprimerErreur('email')
            if (state.body.email2 !=null && e.target.value !=state.body.email2 ){
                 ajouterErreur( 'email2', 'Email différent')
            }else{
                if (Object.keys(errors).includes('email')) delete errors['email']
                supprimerErreur('email2')
            }
        }
        setState({...state, body: {...state.body, ['email']: e.target.value}})
    }
    const handleEmail2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.body.email) {
            ajouterErreur( 'email2', 'Email différent')
        } else {
            supprimerErreur('email2')
        }
        setState({...state, body: {...state.body, ['email2']: e.target.value}})
    }
    const handleFieldChange = (field: string) => {
        return function (e: any) {
            if (!validate(field, e.target.value)) {
               if (field=='sex') ajouterErreur( 'sex', 'Sexe incohérent avec le numéro de sécu')
               if (field=='firstName') ajouterErreur( 'firstName', 'Lettres uniquement')
               if (field=='lastName') ajouterErreur( 'lastName', 'Lettres uniquement')
               if (field=='mobilePhoneNumber') ajouterErreur( 'mobilePhoneNumber', 'Chiffres uniquement uniquement')
               if (field=='dateOfBirth') ajouterErreur( 'dateOfBirth', 'Date incohérente')
               if (field=='nirpp') ajouterErreur( 'nirpp', 'Numéro incohérent ou trop court (15 chiffres minimum)')
               if (field=='addressLine1') ajouterErreur( 'addressLine1', 'Obligatoire')
               if (field=='city') ajouterErreur( 'city', 'Obligatoire')
               if (field=='zipCode') ajouterErreur( 'zipCode', 'Chiffres uniquement (5 chiffres minimum)')
               if (field=='previousSchoolId') ajouterErreur( 'firstName', 'Obligatoire')
            } else {
                supprimerErreur(field)
            }
            setState({...state, body: {...state.body, [field]: e.target.value}})
        }
    }

    const checkBirthCoherence = () => {
        if (!state.body.nirpp || !state.body.dateOfBirth) return true
        const enteredYear = state.body.nirpp.substring(1, 3)
        const selectedYear = String(state.body.dateOfBirth).substring(2, 4)
        const enteredMonth = state.body.nirpp.substring(3, 5)
        const selectedMonth = String(state.body.dateOfBirth).substring(5, 7)
        return (enteredYear === selectedYear && enteredMonth === selectedMonth)
    }

    const checkSexCoherence = () => {
        if (!state.body.nirpp || !state.body.sex) return true
        if (state.body.nirpp && !state.body.sex) return false

        const enteredSex = state.body.nirpp.substring(0, 1)
        const selectedSex = state.body.sex
        if ((enteredSex == "1" && selectedSex == "F") || (enteredSex == "2" && selectedSex == "M") ){
            return false
        }else{
            return true
        }
    }
    const controlToutEtEnvoi =() => {
        let champsObligatoire = false


        if (state.body.sex == null){
            ajouterErreur( 'sex', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.firstName == null){
            ajouterErreur( 'firstName', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.lastName == null){
            ajouterErreur( 'lastName', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.mobilePhoneNumber == null){
            ajouterErreur( 'mobilePhoneNumber', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.dateOfBirth == null){
            ajouterErreur( 'dateOfBirth', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.nirpp == null){
            ajouterErreur( 'nirpp', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.email == null){
            ajouterErreur( 'email', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.email2 == null){
            ajouterErreur( 'email2', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.password == null){
            ajouterErreur( 'password', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.password2 == null){
            ajouterErreur( 'password2', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.addressLine1 == null){
            ajouterErreur( 'addressLine1', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.city == null){
            ajouterErreur( 'city', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.zipCode == null){
            ajouterErreur( 'zipCode', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.body.previousSchoolId == null){
            ajouterErreur( 'previousSchoolId', 'Champs obligatoire')
            champsObligatoire = true
        }
        if(champsObligatoire)
            return

        let retour = true

        if (retour && state.body.sex != null && validate('sex', state.body.sex)  && checkSexCoherence ()){
            supprimerErreur('sex')
            retour = true
        } else  {
            ajouterErreur( 'sex', 'Sexe incohérent avec le numéro de sécu')
            return
        }
        if (retour && state.body.firstName != null && validate('firstName', state.body.firstName)){
            supprimerErreur('firstName')
            retour = true
        } else  {
            ajouterErreur( 'firstName', 'Lettres uniquement')
            return
        }
        if (retour && state.body.lastName != null && validate('lastName', state.body.lastName)){
            supprimerErreur('lastName')
            retour = true
        } else  {
            ajouterErreur( 'lastName', 'Lettres uniquement')
            return
        }
        if (retour && state.body.mobilePhoneNumber != null && validate('mobilePhoneNumber', state.body.mobilePhoneNumber)){
            supprimerErreur('mobilePhoneNumber')
            retour = true
        } else  {
            ajouterErreur( 'mobilePhoneNumber', 'Chiffres uniquement')
            return
        }
        if (retour && state.body.dateOfBirth != null && validate('dateOfBirth', state.body.dateOfBirth.toString()) && checkBirthCoherence()){
            supprimerErreur('dateOfBirth')
            retour = true
        } else  {
            ajouterErreur( 'dateOfBirth', 'Date incohérente')
            return
        }
        if (retour && state.body.nirpp != null && validate('nirpp', state.body.nirpp)){
            supprimerErreur('nirpp')
            retour = true
        } else  {
            ajouterErreur( 'nirpp', 'Numéro incohérent ou trop court (15 chiffres minimum)')
            return
        }
        if (retour && state.body.email != null && validateEmail(state.body.email)){
            supprimerErreur('email')
            retour = true
        } else  {
            ajouterErreur( 'email', 'Email invalide')
            return
        }
        if (retour && state.body.email2 != null && (state.body.email2 === state.body.email)){
            supprimerErreur('email2')
            retour = true
        } else  {
            ajouterErreur( 'email2', 'Email différent')
            return
        }
        if (retour && state.body.password != null && validatePassword( state.body.password)){
            supprimerErreur('password')
            retour = true
        } else  {
            ajouterErreur( 'password', '8 caractères minimum')
            return
        }
        if (retour && state.body.password2 != null && (state.body.password2 === state.body.password)){
             supprimerErreur('password2')
            retour = true
        } else  {
            ajouterErreur( 'password2', 'Mot de passe différent')
            return
        }
        if (retour && state.body.addressLine1 != null && validate('addressLine1', state.body.addressLine1)){
            supprimerErreur('addressLine1')
            retour = true
        } else  {
            ajouterErreur( 'addressLine1', 'Obligatoire')
            return
        }
        if (retour && state.body.city != null && validate('city', state.body.city)){
            supprimerErreur('city')
            retour = true
        } else  {
            ajouterErreur( 'city', 'Obligatoire')
            return
        }
        if (retour && state.body.zipCode != null && validate('zipCode', state.body.zipCode)){
            supprimerErreur('zipCode')
            retour = true
        } else  {
            ajouterErreur( 'zipCode', 'Chiffres uniquement')
            return
        }
        if (retour && state.body.previousSchoolId != null && validate('previousSchoolId', state.body.previousSchoolId.toString())){
            supprimerErreur('previousSchoolId')
            retour = true
        } else  {
            ajouterErreur( 'previousSchoolId', 'Obligatoire')
            return
        }

        if (retour){
            handleSubmit()
        }

    }

    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: t(state.errors[str][0]), show: true}
        if (Object.keys(errors).includes(str)) return {content: t(errors[str][0]), show: true}
        if (!checkBirthCoherence() && str === 'dateOfBirth') return {content: t('error_not_coherent_date_of_birth'), show: true}
        if (!checkSexCoherence() && str === 'sex') return {content: t('error_not_coherent_sex'), show: true}
        return undefined
    };
    const handleSubmit = () => onSubmit(state.body)

    return (
        <VStack align={'start'} spacing={'30px'} w={'90%'}>
            <Box w={'35%'}>
                <SexField label={t('field_sex_label')} onChange={handleFieldChange('sex')} required value={state.body.sex} error={getError('sex')} />
            </Box>
            <Grid w={'100%'} templateColumns={'repeat(2, 1fr)'} templateRows={'repeat(3, 1fr)'} gap={6}>
                <NameField title={t('field_first_name_title')} value={state.body.firstName} onChange={handleFieldChange('firstName')} required error={getError('firstName')} />
                <NameField title={t('field_last_name_title')} value={state.body.lastName} onChange={handleFieldChange('lastName')} required error={getError('lastName')} />
                <PhoneField title={(t('field_mobile_phone_number_title'))} value={state.body.mobilePhoneNumber} onChange={handleFieldChange('mobilePhoneNumber')} required error={getError('mobilePhoneNumber')} />
                <DateField title={t('field_date_of_birth_title')} value={state.body.dateOfBirth} onChange={handleFieldChange('dateOfBirth')} required error={getError('dateOfBirth')} />
            </Grid>
            <Divider borderColor={'gray.200'}/>
            <Flex align={'center'}>
                <NirppField title={t('field_nirpp_title')} value={state.body.nirpp} onChange={handleFieldChange('nirpp')} required error={getError('nirpp')} />
                <Alert fontSize={'xs'} ml={6}>{authT('auth_register_alert_nirpp_text')}</Alert>
            </Flex>
            <Divider borderColor={'gray.200'}/>
            <Grid w={'100%'} templateColumns={'repeat(8, 0.5fr)'} templateRows={'repeat(2, 1fr)'} gap={6}>
                <GridItem colSpan={4}>
                    <EmailField title={t('field_email_title')} value={state.body.email} onChange={handleEmailChange} required error={getError('email')} />
                </GridItem>
                <GridItem colSpan={4}>
                    <EmailField title={t('field_email2_title')} value={state.body.email2} onChange={handleEmail2Change} required error={getError('email2')} />
                </GridItem>
                <GridItem colSpan={4}>
                    <PasswordField title={t('field_password_title')} value={state.body.password} onChange={handlePasswordChange} required error={getError('password')} />
                </GridItem>
                <GridItem colSpan={4}>
                    <PasswordField title={t('field_password2_title')} value={state.body.password2} onChange={handlePassword2Change} required error={getError('password2')} />
                </GridItem>
                <GridItem colSpan={4}>
                    <LocationField title={t('field_address_line1_title')} value={state.body.addressLine1} onChange={handleFieldChange('addressLine1')} required error={getError('addressLine1')} />
                </GridItem>
                <GridItem colSpan={4}>
                    <OptionalField title={t('field_address_line2_title')} value={state.body.addressLine2} onChange={handleFieldChange('addressLine2')} error={getError('addressLine2')} />
                </GridItem>
                <GridItem colSpan={2}>
                    <CityField title={t('field_city_title')} value={state.body.city} onChange={handleFieldChange('city')} required error={getError('city')} />
                </GridItem>
                <GridItem colSpan={2}>
                    <ZipCodeField title={t('field_zip_code_title')} value={state.body.zipCode} onChange={handleFieldChange('zipCode')} required error={getError('zipCode')} />
                </GridItem>
            </Grid>
            <Divider borderColor={'gray.200'}/>
            <Grid w={'100%'} templateColumns={'repeat(2, 1fr)'} templateRows={'repeat(1, 1fr)'} gap={6}>
                <SchoolField title={t('field_previous_school_title')} placeholder={t('field_previous_school_placeholder')} value={state.body.previousSchoolId}
                    onChange={handleFieldChange('previousSchoolId')} required error={getError('previousSchoolId')}
                />
            </Grid>
            <Divider borderColor={'gray.200'}/>
            <Alert status={'warning'} w={'90%'} fontSize={'xs'}>
                <AlertIcon />
                <Container maxW={'container.xl'}>
                    {authT('auth_register_alert_france_only_text')}
                </Container>
            </Alert>
            <Divider borderColor={'gray.200'}/>
            <Container maxW={'container.xl'} fontSize={'xs'}>
                {authT('auth_register_accept_rules_text')}
            </Container>
            <Divider borderColor={'gray.200'}/>
            <Button onClick={controlToutEtEnvoi} isDisabled={Object.keys(state.errors).length !== 0 || Object.keys(errors).length !== 0}>
                {authT('auth_register_button_text')}
            </Button>
        </VStack>
    )
}

export interface RegisterFormState {
    body: {
        sex: 'M' | 'F';
        firstName: string;
        lastName: string;
        mobilePhoneNumber: string;
        dateOfBirth: Date;
        nirpp: string;
        addressLine1: string;
        addressLine2: string;
        zipCode: string;
        city: string;
        country: string;
        previousSchoolId: number;
        email: string;
        email2: string;
        password: string;
        password2: string;
    },
    errors: any;
}

export interface RegisterFormProps extends withOnSubmit, withInitialState {
    errors?: any
}

export default RegisterForm;
