import * as React from "react";
import {PasswordReset} from "../config/types";
import {fetchPasswordReset} from "../services/passwordReset";

export function usePasswordReset(code?: string) {
    const [passwordReset, setPasswordReset] = React.useState<PasswordReset | undefined>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        if (!code) {
            setLoading(false)
            return
        }
        fetchPasswordReset(code).then((data) => {
            setPasswordReset(data)
            setLoading(false)
        }).catch(() => {
            setError('error_no_password_reset')
            setLoading(false)
        })
    }, [code])

    return {passwordReset, loading, error}
}

export default usePasswordReset