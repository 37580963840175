import {Layout} from "../components";
import {Button, Card, Grid, GridItem, Spinner, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import CertificatesTab from "../components/tabs/Certificates";
import IdsTab from "../components/tabs/Ids";
import InsuranceTab from "../components/tabs/Insurance";
import ProfileTab from "../components/tabs/Profile";
import SubscriptionsTab from "../components/tabs/Subscriptions";
import {useTranslation} from "react-i18next";
import useSession from "../hooks/useSession";
import {Product} from "../config/types";
import {useLoaderData} from "react-router-dom";
import {listProducts} from "../services/products";


type ProfileIndex = 'profile' | 'ids' | 'subscriptions' | 'certificates' | 'insurance'

export function ProfilePage() {
    const {t} = useTranslation('profile')
    const {user, setUser, loading} = useSession()
    const {products} = useLoaderData() as ProfilePageLoaderResult
    const tabs = [
        {name: 'profile', component: ProfileTab},
        {name: 'ids', component: IdsTab},
        {name: 'subscriptions', component: SubscriptionsTab},
        {name: 'insurance', component: InsuranceTab},
        {name: 'certificates', component: CertificatesTab},
    ]
    const [state, setState] = useState<HomePageState>({tab: 'profile'})

    const Tab = tabs.find(({name}) => name === state.tab)?.component

    useEffect(() => {
        if (!window.localStorage.getItem(String(process.env['REACT_APP_SESSION_COOKIE'])))
            window.location.replace('/auth/login')
    }, [])

    return (
        <Layout type={'navcentered'}>
            <VStack spacing={6} minW={'100%'} maxW={'100%'}>
                <Card w={'80%'}>
                    <Grid templateColumns={`repeat(${tabs.length}, 1fr)`}>
                        {tabs.map(({name}, index) => {
                            const handleClick = () => setState({...state, tab: (name as ProfileIndex)})
                            return (
                                <GridItem key={`${name}-tab-button`} w={'100%'}>
                                    <Button
                                        variant={name === state.tab ? 'solid' : 'ghost'}
                                        borderLeftRadius={index === 0 ? "md" : "0"}
                                        borderRightRadius={index === tabs.length - 1 ? "md" : "0"}
                                        textColor={'primary'}
                                        isDisabled={name === state.tab}
                                        fontSize={'sm'}
                                        fontWeight={'normal'}
                                        rounded={'none'}
                                        w={'100%'}
                                        _disabled={{
                                            cursor: 'not-allowed',
                                            textColor: 'white',
                                            backgroundColor: 'primary'
                                        }}
                                        _hover={{
                                            backgroundColor: name === state.tab ? 'none' : 'gray.50'
                                        }}
                                        onClick={handleClick}
                                    >
                                        {t(`profile_tab_${name}`)}
                                    </Button>
                                </GridItem>
                            )
                        })}
                    </Grid>
                </Card>
                <Card w={'80%'} maxW={'80%'} p={4}>
                    {loading || !user ? <Spinner color={'primary'} /> :
                    Tab && <Tab user={user} setUser={setUser} products={products} />}
                </Card>
            </VStack>
        </Layout>
    )
}

export async function profilePageLoader() {
    const products = await listProducts()
    return {products}
}

export interface HomePageState {
    tab: ProfileIndex;
}

export interface ProfilePageLoaderResult {
    products: Product[];
}

export default ProfilePage;
