import {asField} from "../../config/as";
import ChooseField from "../ChooseField";
import {useTranslation} from "react-i18next";

export function SexField(props: SexFieldProps) {
    const {t} = useTranslation('common')
    const sexBindings = [
        {name: t('field_choose_male_label'), value: 'M'},
        {name: t('field_choose_female_label'), value: 'F'},
    ]
    return (
        <ChooseField options={sexBindings} {...props} />
    )
}

export interface SexFieldProps extends asField {}

export default SexField