import {Box, Text} from "@chakra-ui/react";
import {useLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Layout} from "../components";
import {asLoader} from "../config/as";

export function PaymentStatusPage() {
    const {t} = useTranslation('payment')
    const {status} = useLoaderData() as PaymentStatusPageLoaderResult;
    if (!status) window.location.replace("/profile")
    return (
        <Layout type={'centered'}>
            <Text fontSize={'3xl'}>{t(`payment_${String(status).toLowerCase()}_title`)}</Text>
            <Box>
                <Text as={'span'}>{t('payment_redirection_text')}, </Text>
                <a href={'/profile'}>
                    <Text as={'span'} textColor={'primary'} _hover={{textDecoration: 'underline'}}>
                        {t('payment_redirection_link').toLowerCase()}
                    </Text>
                </a>
                .
            </Box>
        </Layout>
    )
}

export async function paymentStatusPageLoader({params}: PaymentStatusPageLoaderProps) {
    return {
        status: params.status
    }
}

export interface PaymentStatusPageLoaderProps extends asLoader {}

export interface PaymentStatusPageLoaderResult {
    status: string;
}

export default PaymentStatusPage