import * as React from "react";
import {
    Box,
    Card,
    CardBody,
    Container,
    Divider,
    Flex,
    Heading,
    Image,
    Spinner,
    useToast,
    VStack
} from "@chakra-ui/react";
import PasswordForm from "../components/forms/PasswordForm";
import {useTranslation} from "react-i18next";
import {createPasswordReset} from "../services/passwordReset";
import {useLoaderData} from "react-router-dom";
import {asLoader} from "../config/as";
import usePasswordReset from "../hooks/usePasswordReset";

export function PasswordResetPage() {
    const {b64code} = useLoaderData() as PasswordResetPageLoaderResult
    const {t} = useTranslation('auth')
    const toast = useToast()
    const {loading} = usePasswordReset(b64code && String(b64code))
    const [errors, setErrors] = React.useState<undefined | any>()

    React.useEffect(() => {
        if (window.localStorage.getItem(String(process.env['REACT_APP_SESSION_COOKIE'])))
            window.location.replace('/profile')
    }, [])

    const handleEmailSubmit = (body: any) => {
        const id = toast({description: t('auth_toast_password_loading'), status: 'loading', isClosable: false})
        createPasswordReset(body.email).then((errors) => {
            if (!errors) {
                toast.update(id, {description: t('auth_toast_password_success'), status: 'success', isClosable: true})
                setErrors({})
            } else {
                if (errors?.email) {
                    setErrors({email: ['error_email_not_found']})
                }
                toast.update(id, {description: t('auth_toast_password_error'), status: 'error', isClosable: true})
            }
        }).catch(() => {toast.update(id, {description: t('auth_toast_password_error'), status: 'error', isClosable: true})})
    }
    const handlePasswordSubmit = (body: any) => {
        const id = toast({description: t('auth_toast_password_loading'), status: 'loading', isClosable: false})
        fetch(`${process.env['REACT_APP_API_URL']}/password-reset/${b64code}/`, {
            method: 'PUT', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({password: body.password})
        }).then(async (resp) => {
            if (resp.ok) {
                toast.update(id, {description: t('auth_toast_password_change_success'), status: 'success', isClosable: true})
                await window.location.assign('/auth/login')
            } else {
                toast.update(id, {description: t('auth_toast_password_error'), status: 'error', isClosable: true})
            }
        }).catch(() => {
            toast.update(id, {description: t('auth_toast_password_error'), status: 'error', isClosable: true})
        })
    }

    return (
        <Flex direction={'column'} h={'100vh'} align={'center'} justify={'center'}>
            <Box position={"absolute"} top={4} left={4} zIndex={0}>
                <Image
                    src={"/logo-chambre.png"}
                    alt={'Logo'}
                    w={"459px"}
                    h={"489px"}
                    style={{maxWidth: '100%', height: 'auto'}}
                />
            </Box>
            <Card
                border={'1px'}
                borderColor={'gray.200'}
                boxShadow={'md'}
                px={'12px'}
                py={'18px'}
                zIndex={10}
            >
                <CardBody>
                    {loading ? <Spinner color={'primary'}/> : <VStack spacing={'20px'}>
                        <Heading as={'h1'} fontSize={'xl'} fontWeight={'bold'} textColor={'primary'}>
                            {t('auth_password_form_title')}
                        </Heading>
                        <Container textAlign={'center'} fontSize={'sm'} alignContent={'center'}>
                            {t(b64code ? 'auth_password_form_new_password_text' : 'auth_password_form_description')}
                        </Container>
                        <Divider/>
                        <PasswordForm
                            onSubmit={b64code ? handlePasswordSubmit : handleEmailSubmit}
                            code={b64code ? String(b64code) : undefined}
                            errors={errors}
                        />
                    </VStack>}
                </CardBody>
            </Card>
        </Flex>
    )
}

export function passwordResetPageLoader({request}: PasswordResetPageLoaderProps) {
    const url = new URL(request.url);
    const b64code = url.searchParams.get("c")
    console.log(b64code);
    return {b64code}
}

export interface PasswordResetPageLoaderProps extends asLoader {}

export interface PasswordResetPageLoaderResult {
    b64code?: string;
}

export default PasswordResetPage