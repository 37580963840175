import {asField} from "../../config/as";
import IconField from "../IconField";
import useSchools from "../../hooks/useSchools";

export function SchoolField(props: SchoolFieldProps) {
    const {schools} = useSchools()
    const options = schools?.map((school) => ({name: school.name, value: school.id}))
    return (
        <IconField icon={'school'} options={options} {...props}/>
    )
}

export interface SchoolFieldProps extends asField {}

export default SchoolField;