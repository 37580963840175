import * as React from "react";
import {Alert, AlertIcon, Heading, useToast, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {withErrorsRequired, withSetUserRequired, withUserRequired} from "../../config/withs";
import ProfileForm from "../forms/ProfileForm";
import authenticatedRequest from "../../utils/authenticatedRequest";
import {camelToSnake, snakeToCamel} from "../../utils/cases";
import {User} from "../../config/types";

export function Profile({user, setUser}: ProfileProps) {
    const {t} = useTranslation('profile')
    const {t:commonT} = useTranslation('common')
    const [state, setState] = React.useState<ProfileState>({errors: {}})
    const toast = useToast()
    const handleSubmit = async (body: User) => {
        const id = toast({status: "loading", description: commonT('toast_loading_title'), isClosable: false})
        const snakeBody = camelToSnake(body)
        const resp = await authenticatedRequest({
            url: `${process.env['REACT_APP_API_URL']}/users/${user.id}/`,
            fetchProps: {
                method: 'PUT',
                body: JSON.stringify(snakeBody),
                headers: {'Content-Type': 'application/json'}
        }}).catch(() => {
            toast.update(id, {status: "error", description: commonT('toast_error_title'), isClosable: true})
        })
        const data = await resp?.json()
        if (resp?.status !== 200) {
            toast.update(id, {status: "error", description: commonT('toast_error_title'), isClosable: true})
            setState({...state, errors: {...data}})
        } else if (data) {
            setState({errors: {}})
            setUser({...snakeToCamel(data)})
            toast.update(id, {status: "success", description: t('profile_toast_success_title'), isClosable: true})
        }
    }

    return (
        <VStack spacing={8}>
            <Heading fontSize={'md'} textColor={'primary'}>{t('profile_form_title')}</Heading>
            <Alert status={'info'} fontSize={'xs'}>
                <AlertIcon />
                {t('profile_tab_confidentiality_alert')}
            </Alert>
            <ProfileForm user={user} errors={state.errors} onSubmit={handleSubmit} />
        </VStack>
    )
}

interface ProfileState extends withErrorsRequired  {
}

export interface ProfileProps extends withUserRequired, withSetUserRequired {}

export default Profile