import {camelToSnake, snakeToCamel} from "../utils/cases";
import {authenticatedRequest} from "../utils/authenticatedRequest";
import {User} from "../config/types";

export async function fetchUser(): Promise<User | null> {
    const resp = await authenticatedRequest({
        url: `${process.env['REACT_APP_API_URL']}/session/`,
    })
    if (!resp?.ok) return null
    const data = await resp?.json()
    return snakeToCamel(data)
}

export async function createInsurance(body: any) {
    const resp = await authenticatedRequest({
        url: `${process.env['REACT_APP_API_URL']}/insurances/`,
        fetchProps: {
            method: 'POST',
            body: JSON.stringify(camelToSnake(body)),
            headers: {'Content-Type': 'application/json'}
        }
    })
    const data = await resp?.json()
    return snakeToCamel(data)
}

export async function resetEmail(body: any) {
    const resp = await fetch(`${process.env['REACT_APP_API_URL']}/email-reset/`, {
        method: 'PUT',
        body: JSON.stringify(camelToSnake(body)),
        headers: {'Content-Type': 'application/json'}
    })
    if (resp?.status !== 204) {
        const data = await resp?.json()
        return snakeToCamel(data)
    } else {
        return undefined
    }
}