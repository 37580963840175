import * as React from "react";
import {asForm, asLoader} from "../../config/as";
import {Divider, VStack} from "@chakra-ui/react";
import NirppField from "../fields/NirppField";
import {useTranslation} from "react-i18next";
import DateField from "../fields/DateField";
import EmailField from "../fields/EmailField";
import Button from "../Button";
import {addError} from "../../utils/errors";
import {ChangeEvent, useState} from "react";
import {withErrorsRequired} from "../../config/withs";
import {
    validateDateOfBirth,
    validateEmail,
    validateNirpp, validateNotEmpty,
} from "../../utils/validators";



export function EmailResetForm({errors={}, initialState = {},onSubmit = () => {}}: EmailResetFormProps) {
    const [state, setState] = useState<EmailResetFormState>({ "nirpp": "", "dateOfBirth": "", "email": "", "email2": "", errors: {} })
    const {t} = useTranslation('common')

    const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }

    const handleNirppChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!validateNirpp(e.target.value)) {
            ajouterErreur('nirpp', 'Numéro incohérent ou trop court (15 chiffres minimum)')
        } else {
            supprimerErreur('nirpp')
        }
        setState({...state, nirpp: e.target.value})
    }
    const handleDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!validateDateOfBirth(e.target.value)) {
            ajouterErreur('dateOfBirth', 'Numéro incohérent ou trop court (15 chiffres minimum)')
        } else {
            supprimerErreur('dateOfBirth')
        }
        setState({...state, dateOfBirth: e.target.value})
    }
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validateEmail(e.target.value)) {
            ajouterErreur( 'email', 'Email invalide')
        } else {
            supprimerErreur('email')
            if (state.email2 !=null && e.target.value !=state.email2 ){
                 ajouterErreur( 'email2', 'Email différent')
            }else{
                if (Object.keys(errors).includes('email')) delete errors['email']
                supprimerErreur('email2')
            }
        }
        setState({...state, email:   e.target.value})
    }
    const handleEmail2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.email) {
            ajouterErreur( 'email2', 'Email différent')
        } else {
            supprimerErreur('email2')
        }
        setState({...state, email2:   e.target.value})
    }
    const checkBirthCoherence = () => {
        if (!state.nirpp || !state.dateOfBirth) return true
        const enteredYear = state.nirpp.substring(1, 3)
        const selectedYear = String(state.dateOfBirth).substring(2, 4)
        const enteredMonth = state.nirpp.substring(3, 5)
        const selectedMonth = String(state.dateOfBirth).substring(5, 7)
        return (enteredYear === selectedYear && enteredMonth === selectedMonth)
    }

    // const canSubmit = validateEmail(state.email) && validateNirpp(state.nirpp) && validateDateOfBirth(state.dateOfBirth) && state.email === state.email2

    const controlToutEtEnvoi =() => {
        let champsObligatoire = false
        if (!validateNotEmpty(state.nirpp)){
            ajouterErreur( 'nirpp', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (!validateNotEmpty(state.dateOfBirth)){
            ajouterErreur( 'dateOfBirth', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (!validateNotEmpty(state.email)){
            ajouterErreur( 'email', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (!validateNotEmpty(state.email2)){
            ajouterErreur( 'email2', 'Champs obligatoire')
            champsObligatoire = true
        }
        if(champsObligatoire)
            return

        let retour = true

        if (retour && state.dateOfBirth != null && validateDateOfBirth( state.dateOfBirth.toString()) && checkBirthCoherence()){
            supprimerErreur('dateOfBirth')
            retour = true
        } else  {
            ajouterErreur( 'dateOfBirth', 'Date incohérente')
            return
        }
        if (retour && state.nirpp != null && validateNirpp(state.nirpp)){
            supprimerErreur('nirpp')
            retour = true
        } else  {
            ajouterErreur( 'nirpp', 'Numéro incohérent ou trop court (15 chiffres minimum)')
            return
        }

        if (retour && state.email != null && validateEmail(state.email)){
            supprimerErreur('email')
            retour = true
        } else  {
            ajouterErreur( 'email', 'Email invalide')
            return
        }

        if (retour && state.email2 != null && (state.email2 === state.email)){
            supprimerErreur('email2')
            retour = true
        } else  {
            ajouterErreur( 'email2', 'Email différent')
            return
        }

        if (retour){
            handleSubmit()
        }

    }
    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: t(state.errors[str][0]), show: true}
        if (Object.keys(errors).includes(str)) return {content: t(errors[str][0]), show: true}
        if (!checkBirthCoherence() && str === 'dateOfBirth') return {content: t('error_not_coherent_date_of_birth'), show: true}
        return undefined
    };
    const handleSubmit = () => {onSubmit(state)}

    return (
        <VStack spacing={6}>
            <NirppField title={t('field_nirpp_title')} onChange={handleNirppChange} value={state.nirpp} error={getError('nirpp')} />
            <DateField title={t('field_date_of_birth_title')} onChange={handleDateOfBirthChange} value={state.dateOfBirth} error={getError('dateOfBirth')} />
            <Divider />
            <EmailField title={t('field_new_email_title')} onChange={handleEmailChange} value={state.email} error={getError('email')} />
            <EmailField title={t('field_email2_title')} onChange={handleEmail2Change} value={state.email2} error={getError('email2')} />
            <Button isDisabled={Object.keys(state.errors).length !== 0 || Object.keys(errors).length !== 0} onClick={controlToutEtEnvoi}>{t('button_submit_label')}</Button>
        </VStack>
    )
}

export interface EmailResetFormState {
    nirpp: string;
    dateOfBirth: string,
    email: string;
    email2: string;
    errors: any;
}
export interface EmailResetFormProps extends asForm {
    errors?: any;
}
export default EmailResetForm