import {asField} from "../../config/as";
import IconField from "../IconField";

export function CityField(props: CityFieldProps) {
    return (
        <IconField icon={'city'} {...props} />
    )
}

export interface CityFieldProps extends asField {}

export default CityField;