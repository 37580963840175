import {asField} from "../../config/as";
import {IconField} from "../IconField";

export function LocationField(props: LocationFieldProps) {
    return (
        <IconField icon={'location'} {...props} />
    )
}

export interface LocationFieldProps extends asField {}

export default LocationField;