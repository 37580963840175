import {Button, Spacer, VStack} from "@chakra-ui/react";
import {asForm} from "../../config/as";
import {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {EmailField, PasswordField} from "../fields";
import {addError} from "../../utils/errors";
import {validateEmail, validatePassword} from "../../utils/validators";
import {withInitialState, withOnSubmit} from "../../config/withs";

export function PasswordForm({code, errors = {}, onSubmit = () => {}, initialState = {}}: PasswordFormProps) {
    const {t} = useTranslation('common')
    const [state, setState] = useState<PasswordFormState>({body: initialState, errors: {}})

    const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validateEmail(e.target.value)) {
            ajouterErreur( 'email', 'Email invalide')
        } else {
            supprimerErreur('email')
        }
        setState({...state, body: {...state.body, ['email']: e.target.value}})
    }
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validatePassword(e.target.value)) {
            ajouterErreur( 'password', '8 caractères minimum')
        } else {
            supprimerErreur('password')
        }
        setState({...state, body: {...state.body, ['password']: e.target.value}})
    }
    const handlePassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.body.password) {
            ajouterErreur( 'password2', 'Mot de passe différent')
        } else {
            supprimerErreur('password2')
        }
        setState({...state, body: {...state.body, ['password2']: e.target.value}})
    }

    const controlToutEtEnvoi =() => {
         let champsObligatoire = false

         if (!!code) {
             if (state.body.password === undefined) {
                 ajouterErreur('password', 'Champs obligatoire')
                 champsObligatoire = true
             }
             if (state.body.password2 === undefined) {
                 ajouterErreur('password2', 'Champs obligatoire')
                 champsObligatoire = true
             }

         }else {
             if (state.body.email === undefined) {
                 ajouterErreur('email', 'Champs obligatoire')
                 champsObligatoire = true
             }
         }

         if(champsObligatoire)
             return

         let retour = true

         if (!!code) {
             if (retour && state.body.password != null && validatePassword(state.body.password)) {
                 supprimerErreur('password')
                 retour = true
             } else {
                 ajouterErreur('password', '8 caractères minimum')
                 return
             }
             if (retour && state.body.password2 != null && (state.body.password2 === state.body.password)){
                supprimerErreur('password2')
                retour = true
             } else  {
                 ajouterErreur( 'password2', 'Mot de passe différent')
                 return
             }
         } else {
             if (retour && state.body.email != null && validateEmail(state.body.email)){
                supprimerErreur('email')
                retour = true
             } else  {
                ajouterErreur( 'email', 'Email invalide')
                return
             }
         }

         if (retour){
             handleSubmit()
         }

    }

    const getError = (str: string) => {
        if (Object.keys(errors).includes(str)) return {content: t(errors[str][0]), show: true}
        if (Object.keys(state.errors).includes(str)) return {content: t(state.errors[str][0]), show: true}
        return undefined
    }

    const handleSubmit = () => {onSubmit(state.body)};


    return (
        <VStack spacing={'10px'} w={'100%'} >
            { (!!code)
                ? <>
                    <PasswordField title={t('field_password_title')} placeholder={t('field_password_placeholder')} value={state.body?.password} onChange={handlePasswordChange} required error={getError('password')}/>
                    <PasswordField title={t('field_password2_title')} placeholder={t('field_password2_title')} value={state.body?.password2} onChange={handlePassword2Change} required error={getError('password2')}/>
                  </>
                : <EmailField placeholder={t('field_email_placeholder')} value={state.body?.email} onChange={handleEmailChange} error={getError('email')}/>
            }

            <Spacer />
            <Button onClick={controlToutEtEnvoi} backgroundColor={'primary'} _hover={{ backgroundColor: 'darker' }} textColor={'white'} size={'sm'} >
                {t('button_continue_label')}
            </Button>
        </VStack>
    )
    // return (
    //     <VStack spacing={'10px'} w={'100%'}>
    //         {(!!code) ?
    //             <PasswordField placeholder={t('field_password_placeholder')} value={state.body?.password} onChange={handlePasswordChange} error={getError('password')} />
    //         :
    //             <EmailField placeholder={t('field_email_placeholder')} value={state.body?.email} onChange={handleEmailChange} error={getError('email')} />
    //         }
    //         <Spacer />
    //         <Button onClick={controlToutEtEnvoi} backgroundColor={'primary'} _hover={{ backgroundColor: 'darker' }} textColor={'white'} size={'sm'} >
    //             {t('button_continue_label')}
    //         </Button>
    //     </VStack>
    // )
}

export interface PasswordFormState {
    body: {
        email: string;
        password: string;
        password2: string;
    },
    errors: any;
}

export interface PasswordFormProps extends  withOnSubmit, withInitialState {
    code?: string;
    errors?: any;
}

export default PasswordForm;

