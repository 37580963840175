import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
    colors: {
        primary: "#72c02c",
        darker: "#378A00",
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
});

export default theme;