import * as React from "react";
import {
    Box,
    Card,
    CardBody,
    Container,
    Divider,
    Flex,
    Heading,
    Image,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {EmailResetForm} from "../components/forms/EmailResetForm";
import {resetEmail} from "../services/user";


export function EmailResetPage() {
    const {t} = useTranslation('auth')
    const toast = useToast()
    const [errors, setErrors] = React.useState<undefined | any>()

    React.useEffect(() => {
        if (window.localStorage.getItem(String(process.env['REACT_APP_SESSION_COOKIE'])))
            window.location.replace('/profile')
    }, [])

    const handleSubmit = async (body: any) => {
        const id = toast({description: t('auth_toast_email_reset_loading'), status: 'loading', isClosable: false})
        const data = await resetEmail(body)
        if (data) {
            toast.update(id, {description: t('auth_toast_email_reset_error'), status: 'error', isClosable: true})
            setErrors(data)
        } else {
            toast.update(id, {description: t('auth_toast_email_reset_success'), status: 'success', isClosable: true})
            setErrors(undefined)
            window.location.assign("/auth/login")
        }
    }

    return (
        <Flex direction={'column'} h={'100vh'} align={'center'} justify={'center'}>
            <Box position={"absolute"} top={4} left={4} zIndex={0}>
                <Image
                    src={"/logo-chambre.png"}
                    alt={'Logo'}
                    w={"459px"}
                    h={"489px"}
                    style={{maxWidth: '100%', height: 'auto'}}
                />
            </Box>
            <Card
                border={'1px'}
                borderColor={'gray.200'}
                boxShadow={'md'}
                px={'12px'}
                py={'18px'}
                zIndex={10}
            >
                <CardBody>
                    <VStack spacing={'20px'}>
                        <Heading as={'h1'} fontSize={'xl'} fontWeight={'bold'} textColor={'primary'}>
                            {t('auth_email_form_title')}
                        </Heading>
                        <Container textAlign={'center'} fontSize={'sm'} alignContent={'center'}>
                            {t('auth_email_form_description')}
                        </Container>
                        <Text fontSize={"sm"}>
                            {t('auth_email_redirect_login')}
                            <Text as={'span'} color={'primary'} ml={1} _hover={{textDecoration: 'underline'}}>
                                <a href={"/auth/login"}>{t('auth_email_redirect_login_link')}</a>.
                            </Text>
                        </Text>
                        <Divider/>
                        <EmailResetForm errors={errors} onSubmit={handleSubmit}/>
                        <Text fontSize={'sm'}>{t('auth_email_support_text')},
                            <Text ml={'4px'} textColor={'primary'} _hover={{textDecoration: 'underline'}} as={'span'}>
                                <a href={'mailto:contact@chambre-syndicale-sophrologie.fr?Subject=Oubli%20email'}>
                                    {t('auth_email_support_link').toLowerCase()}.
                                </a>
                            </Text>
                        </Text>
                    </VStack>
                </CardBody>
            </Card>
        </Flex>
    )
}

export default EmailResetPage