import {withErrors, withOnSubmit, withUserRequired} from "../../config/withs";
import {Alert, AlertIcon, HStack, StackDivider, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {
    ApeCodeField,
    CityField,
    CountryField,
    DateField,
    LocationField,
    NameField,
    NirppField,
    OptionalField,
    PhoneField,
    SchoolField,
    SexField,
    SiretField,
    WebsiteField,
    ZipCodeField
} from "../fields";
import Button from "../Button";
import {useState} from "react";
import {
    validateAlpha,
    validateName,
    validateNotEmpty,
    validatePhoneNumber,
    validateSiret,
    validateWebsite,
    validateZipcode,
    valideApeCode
} from "../../utils/validators";
import {addError} from "../../utils/errors";
import {User} from "../../config/types";

function validate(name: string, value: string) {
    switch (name) {
        // case 'sex' : return validateSex(value)
        case 'firstName': return validateNotEmpty(value) && validateName(value)
        case 'lastName': return validateNotEmpty(value) && validateName(value)
        // case 'dateOfBirth': return validateNotEmpty(value) && validateDateOfBirth(value)
        // case 'nirpp': return validateNotEmpty(value) && validateNirpp(value)
        case 'siret': return validateSiret(value)
        case 'apeCode': return valideApeCode(value)
        case 'mobilePhoneNumber': return validateNotEmpty(value) && validatePhoneNumber(value)
        case 'website': return validateWebsite(value)
        case 'addressLine1': return validateNotEmpty(value)
        case 'city': return validateNotEmpty(value) && validateAlpha(value)
        case 'zipCode': return validateNotEmpty(value) && validateZipcode(value)
        case 'previousSchoolId': return validateNotEmpty(value)
        default: return true
    }
}

export function ProfileForm({errors ={}, onSubmit = () => {}, user}: ProfileFormProps) {
    const {t} = useTranslation('common')
    const {t: profileT} = useTranslation('profile')
    const [state, setState] = useState<ProfileFormState>({user, errors: {}})

     const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }
    const handleFieldChange = (field: string) => {
        return function (e: any) {
            if (!validate(field, e.target.value)) {
                // if (field=='sex') ajouterErreur( 'sex', 'Sexe incohérent avec le numéro de sécu')
                if (field=='firstName') ajouterErreur( 'firstName', 'Lettres uniquement')
                if (field=='lastName') ajouterErreur( 'lastName', 'Lettres uniquement')
                if (field=='siret') ajouterErreur( 'siret', 'siret incohérent ')
                if (field=='ape') ajouterErreur( 'ape', 'Ape incohérent ')
                if (field=='mobilePhoneNumber') ajouterErreur( 'mobilePhoneNumber', 'Chiffres uniquement')
                if (field=='website') ajouterErreur( 'website', 'url non conforme')

                // if (field=='dateOfBirth') ajouterErreur( 'dateOfBirth', 'Date incohérente')
                // if (field=='nirpp') ajouterErreur( 'nirpp', 'Numéro incohérent ou trop court (15 chiffres minimum)')
                if (field=='addressLine1') ajouterErreur( 'addressLine1', 'Obligatoire')
                if (field=='city') ajouterErreur( 'city', 'Obligatoire')
                if (field=='zipCode') ajouterErreur( 'zipCode', 'Chiffres uniquement (5 chiffres minimum)')
                if (field=='previousSchoolId') ajouterErreur( 'previousSchoolId', 'Obligatoire')
            } else {
                supprimerErreur(field)
            }
            setState({...state, user: {...state.user, [field]: e.target.value}})
        }
    }
        
    const checkBirthCoherence = () => {
        if (!state.user.nirpp || !state.user.dateOfBirth) return true
        const enteredYear = state.user.nirpp.substring(1, 3)
        const selectedYear = String(state.user.dateOfBirth).substring(2, 4)
        const enteredMonth = state.user.nirpp.substring(3, 5)
        const selectedMonth = String(state.user.dateOfBirth).substring(5, 7)
        return (enteredYear === selectedYear && enteredMonth === selectedMonth)
    }
    
    const checkSexCoherence = () => {
        if (!state.user.nirpp || !state.user.sex) return true
        if (state.user.nirpp && !state.user.sex) return false

        const enteredSex = state.user.nirpp.substring(0, 1)
        const selectedSex = state.user.sex
        if ((enteredSex == "1" && selectedSex == "F") || (enteredSex == "2" && selectedSex == "M") ){
            return false
        }else{
            return true
        }
    }
    
    const controlToutEtEnvoi =() => {
        let champsObligatoire = false


        if (state.user.firstName == null){
            ajouterErreur( 'firstName', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.lastName == null){
            ajouterErreur( 'lastName', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.mobilePhoneNumber == null){
            ajouterErreur( 'mobilePhoneNumber', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.addressLine1 == null){
            ajouterErreur( 'addressLine1', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.city == null){
            ajouterErreur( 'city', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.zipCode == null){
            ajouterErreur( 'zipCode', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.user.previousSchoolId == null){
            ajouterErreur( 'previousSchoolId', 'Champs obligatoire')
            champsObligatoire = true
        }
        if(champsObligatoire)
            return

        let retour = true

        if (retour && state.user.firstName != null && validate('firstName', state.user.firstName)){
            supprimerErreur('firstName')
            retour = true
        } else  {
            ajouterErreur( 'firstName', 'Lettres uniquement')
            return
        }
        if (retour && state.user.lastName != null && validate('lastName', state.user.lastName)){
            supprimerErreur('lastName')
            retour = true
        } else  {
            ajouterErreur( 'lastName', 'Lettres uniquement')
            return
        }
        if (retour && state.user.mobilePhoneNumber != null && validate('mobilePhoneNumber', state.user.mobilePhoneNumber)){
            supprimerErreur('mobilePhoneNumber')
            retour = true
        } else  {
            ajouterErreur( 'mobilePhoneNumber', 'Chiffres uniquement')
            return
        }
        // if (retour && state.user.siret != null && validate('siret', state.user.siret)){
        //     supprimerErreur('siret')
        //     retour = true
        // } else  {
        //     ajouterErreur( 'siret', 'Chiffres uniquement')
        //     return
        // }
        // if (retour && state.user.apeCode != null && validate('apeCode', state.user.apeCode)){
        //     supprimerErreur('apeCode')
        //     retour = true
        // } else  {
        //     ajouterErreur( 'apeCode', '???')
        //     return
        // }
        if (retour && state.user.addressLine1 != null && validate('addressLine1', state.user.addressLine1)){
            supprimerErreur('addressLine1')
            retour = true
        } else  {
            ajouterErreur( 'addressLine1', 'Obligatoire')
            return
        }
        if (retour && state.user.city != null && validate('city', state.user.city)){
            supprimerErreur('city')
            retour = true
        } else  {
            ajouterErreur( 'city', 'Obligatoire')
            return
        }
        if (retour && state.user.zipCode != null && validate('zipCode', state.user.zipCode)){
            supprimerErreur('zipCode')
            retour = true
        } else  {
            ajouterErreur( 'zipCode', 'Chiffres uniquement')
            return
        }
        if (retour && state.user.previousSchoolId != null && validate('previousSchoolId', state.user.previousSchoolId.toString())){
            supprimerErreur('previousSchoolId')
            retour = true
        } else  {
            ajouterErreur( 'previousSchoolId', 'Obligatoire')
            return
        }

        if (retour){
            handleSubmit()
        }

    }
    
    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: state.errors[str][0], show: true}
        if (Object.keys(errors).includes(str)) return {content: errors[str][0], show: true}
        if (!checkBirthCoherence() && str === 'dateOfBirth') return {content: t('error_not_coherent_date_of_birth'), show: true}
        if (!checkSexCoherence() && str === 'sex') return {content: t('error_not_coherent_sex'), show: true}
        return undefined
    };
    
    const handleSubmit = () => onSubmit(state.user)
    

    return (
        <VStack divider={<StackDivider />} w={'100%'} spacing={6}>
            <VStack w={'100%'}>
                <NameField label={t('field_member_id_label')} isDisabled value={String(state.user.id)}/>
                <DateField label={t('field_subscription_date_label')} isDisabled value={state.user.createdAt ? String(state.user.createdAt) : undefined}
                />
            </VStack>
            <VStack w={'100%'}>
                <SexField label={t('field_sex_label')} onChange={handleFieldChange('sex')} isDisabled value={state.user?.sex} error={getError('sex')} />
                <NameField label={t('field_first_name_label')} onChange={handleFieldChange('firstName')} required value={state.user?.firstName} error={getError('firstName')} />
                <NameField label={t('field_last_name_label')} onChange={handleFieldChange('lastName')} required value={state.user?.lastName} error={getError('lastName')} />
            </VStack>
            <VStack w={'100%'}>
                <DateField label={t('field_date_of_birth_label')} onChange={handleFieldChange('dateOfBirth')} isDisabled value={state.user?.dateOfBirth ? String(state.user.dateOfBirth) : undefined} error={getError('dateOfBirth')} />
                <Alert status={'info'} fontSize={'xs'}>
                    <AlertIcon />
                    {profileT('profile_tab_nirpp_alert')}
                </Alert>
                <NirppField label={t('field_nirpp_label')} onChange={handleFieldChange('nirpp')} isDisabled value={state.user?.nirpp} error={getError('nirpp')} />
                <SiretField label={t('field_siret_label')} onChange={handleFieldChange('siret')} value={state.user?.siret} error={getError('siret')} />
                <ApeCodeField label={t('field_ape_code_label')} onChange={handleFieldChange('apeCode')} value={state.user?.apeCode} error={getError('apeCode')} />
                <PhoneField label={t('field_mobile_phone_label')} onChange={handleFieldChange('mobilePhoneNumber')} required value={state.user?.mobilePhoneNumber} error={getError('mobilePhoneNumber')} />
                <WebsiteField label={t('field_website_label')} onChange={handleFieldChange('website')} value={state.user?.website} error={getError('website')} />
            </VStack>
            <VStack w={'100%'}>
                <LocationField label={t('field_address_line_1_label')} onChange={handleFieldChange('addressLine1')} required value={state.user?.addressLine1} error={getError('addressLine1')} />
                <OptionalField label={t('field_address_line_2_label')} onChange={handleFieldChange('addressLine2')} value={state.user?.addressLine2} error={getError('addressLine2')} />
                <ZipCodeField label={t('field_zipcode_label')} onChange={handleFieldChange('zipCode')} required value={state.user?.zipCode} error={getError('zipCode')} />
                <CityField label={t('field_city_label')} onChange={handleFieldChange('city')} required value={state.user?.city} error={getError('city')} />
                <CountryField label={t('field_country_label')} isDisabled value={state.user?.country} />
                <SchoolField label={t('field_previous_school_label')} placeholder={t('field_previous_school_placeholder')}
                             onChange={handleFieldChange('previousSchoolId')} required value={state.user?.previousSchoolId} error={getError('previousSchoolId')}
                />
            </VStack>
            <HStack w={'100%'}>
                <Button onClick={controlToutEtEnvoi} isDisabled={Object.keys(state.errors).length !== 0 || Object.keys(errors).length !== 0} >
                    {t('button_save_label')}
                </Button>
            </HStack>
        </VStack>
    )
}

export interface ProfileFormState {
    user: User;
    errors: any;
}

export interface ProfileFormProps extends withErrors, withOnSubmit, withUserRequired {}

export default ProfileForm