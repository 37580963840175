import {asField} from "../../config/as";
import IconField from "../IconField";


export function ZipCodeField({...props}: ZipCodeFieldProps) {
    return <IconField icon={'location'} format={'## ###'} {...props} />
}

export interface ZipCodeFieldProps extends asField {}

export default ZipCodeField