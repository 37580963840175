import {Button as ChakraButton, HStack, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {ChangeEvent, useState} from "react";
import {asForm} from "../../config/as";
import {withErrors, withErrorsRequired, withUserRequired} from "../../config/withs";
import Button from "../../components/Button";
import {EmailField} from "../fields/EmailField";
import {validateEmail} from "../../utils/validators";
import {addError} from "../../utils/errors";

export function EditEmailForm({errors = {}, onSubmit = () => {}, user}: EditEmailFormProps) {
    const {t} = useTranslation('common')
    const [state, setState] = useState<EditEmailFormState>({email: "", email2: "", errors: {}})

    const ajouterErreur = (clef: string, message: string) => {
        supprimerErreur(clef)
        setState({...state, errors: addError(state.errors, clef, message)})
    }
    const supprimerErreur = (clef: string) => {
        if (Object.keys(errors).includes(clef)) delete errors[clef]
        if (Object.keys(state.errors).includes(clef)) delete state.errors[clef]
    }

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!validateEmail(e.target.value)) {
            ajouterErreur( 'email', 'Email invalide')
        } else {
            supprimerErreur('email')
            if (state.email2 !=null && e.target.value !=state.email2 ){
                 ajouterErreur( 'email2', 'Email différent')
            }else{
                if (Object.keys(errors).includes('email')) delete errors['email']
                supprimerErreur('email2')
            }
        }
        setState({...state, email:   e.target.value})
    }
    const handleEmail2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== state.email) {
            ajouterErreur( 'email2', 'Email différent')
        } else {
            supprimerErreur('email2')
        }
        setState({...state, email2:   e.target.value})
    }

    const controlToutEtEnvoi =() => {
        let champsObligatoire = false
        if (state.email == null){
            ajouterErreur( 'email', 'Champs obligatoire')
            champsObligatoire = true
        }
        if (state.email2 == null){
            ajouterErreur( 'email2', 'Champs obligatoire')
            champsObligatoire = true
        }
        if(champsObligatoire)
            return

        let retour = true

        if (retour && state.email != null && validateEmail(state.email)){
            supprimerErreur('email')
            retour = true
        } else  {
            ajouterErreur( 'email', 'Email invalide')
            return
        }
        if (retour && state.email2 != null && (state.email2 === state.email)){
            supprimerErreur('email2')
            retour = true
        } else  {
            ajouterErreur( 'email2', 'Email différent')
            return
        }

        if (retour){
            handleSubmit()
        }

    }
    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: state.errors[str][0], show: true}
        if (Object.keys(errors).includes(str)) return {content: errors[str][0], show: true}
        return undefined
    };

    const isDisabled = Object.keys(state.errors).length !== 0 ||
        state.email !== state.email2 || !validateEmail(state.email)

    const handleSubmit = () => {onSubmit({email: state.email})}
    const handleCancel = () => {setState({email: user.email, email2: "", errors: {}})}

    return (
        <VStack w={'100%'} spacing={6}>
            <EmailField label={t('field_current_email_label')} isDisabled={true} value={user.email} />
            <EmailField label={t('field_new_email_label')} value={state.email} onChange={handleEmailChange} required error={getError('email')} />
            <EmailField label={t('field_confirm_email_label')} value={state.email2} onChange={handleEmail2Change} required error={getError('email2')} />
            <HStack w={'100%'}>
                <ChakraButton colorScheme={'red'} fontWeight={'normal'} size={'sm'} onClick={handleCancel}>
                    {t('button_cancel_label')}
                </ChakraButton>
                <Button
                    isDisabled={isDisabled}
                    onClick={controlToutEtEnvoi}
                >
                    {t('button_save_label')}
                </Button>
            </HStack>
        </VStack>
    )
}

export interface EditEmailFormState extends withErrorsRequired {
    email: string;
    email2: string;
}

export interface EditEmailFormProps extends asForm, withUserRequired, withErrors {}

export default EditEmailForm