import {authenticatedRequest} from "../utils/authenticatedRequest";

export async function fetchSubscriptionAttestation(id: number): Promise<string | undefined> {
    try {
        const resp = await authenticatedRequest({url: `${process.env['REACT_APP_API_URL']}/documents/attestation/${id}/`})
        if (!resp.ok) {
            return undefined
        } else {
            const blob = await resp.blob()
            return URL.createObjectURL(blob)
        }
    } catch (e) {
        return undefined
    }
}

export async function fetchSubscriptionBill(id: number): Promise<string | undefined> {
    try {
        const resp = await authenticatedRequest({url: `${process.env['REACT_APP_API_URL']}/documents/bill/${id}/`})
        if (!resp.ok) {
            return undefined
        } else {
            const blob = await resp.blob()
            return URL.createObjectURL(blob)
        }
    } catch (e) {
        return undefined
    }
}

export async function fetchSubscriptionCertificate(id: number): Promise<string | undefined> {
    try {
        const resp = await authenticatedRequest({url: `${process.env['REACT_APP_API_URL']}/documents/certificate/${id}/`})
        if (!resp.ok) {
            return undefined
        } else {
            const blob = await resp.blob()
            return URL.createObjectURL(blob)
        }
    } catch (e) {
        return undefined
    }
}
