import {asField} from "../../config/as";
import IconField from "../IconField";
export function OptionalField(props: OptionalFieldProps) {
    return (
        <IconField icon={'add'} {...props} />
    )
}

export interface OptionalFieldProps extends asField {}

export default OptionalField;