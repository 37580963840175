import * as React from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Card,
    Checkbox,
    Flex,
    Image,
    Heading,
    HStack,
    Spinner,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {loginOrRegister} from "../services/annuaire";
import {Button, Layout} from "../components";
import {useTranslation} from "react-i18next";
import {FiExternalLink} from "react-icons/fi";
import useSession from "../hooks/useSession";
import {hasActiveSubscription} from "../services/subscriptions";
import {User} from "../config/types";

export function BookPage() {
    const {t} = useTranslation('annuaire')
    const [checked, setChecked] = React.useState(false)
    const toast = useToast()
    const {user, loading} = useSession()

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked);
    const handleCreate = async () => {
        const id = toast({status: 'loading', description: t('annuaire_create_toast_loading'), isClosable: false})
        if (!user) return;
        try {
            await loginOrRegister()
            toast.update(id, {status: 'success', description: t('annuaire_create_toast_success'), isClosable: true})
            window.location.assign('/profile');
        } catch (_) {
            toast.update(id, {status: 'error', description: t('annuaire_create_toast_error'), isClosable: true})
        }
    }

    if (!loading && !user) {
        window.location.replace("/auth/login")
        return <></>
    }

    return (
        <Layout type={'navcentered'}>
            <Card w={'80%'} p={4}>
                {loading ? <Spinner color={'primary'} /> :
                <VStack spacing={12}>
                    <Heading fontSize={'md'} textColor={'primary'}>{t('annuaire_title')}</Heading>
                    {hasActiveSubscription(user as User) ? <VStack align={'start'} spacing={4} fontSize={'sm'}>
                        {user && user.annuaireId ? <>
                            <Text>
                                {t('annuaire_already_exists_id', {id: user.annuaireId, userId: user.id})}
                            </Text>
                            {user.ficheAnnuaireId && <Text mt={-4}>
                                {t('annuaire_fiche_already_exists_id', {id: user.ficheAnnuaireId})}
                            </Text>}
                            {!user.ficheAnnuaireId && <Alert status={'warning'}>
                                <AlertIcon/>
                                {t('annuaire_warning_not_created')}
                            </Alert>}
                            <Alert status={'error'}>
                                <AlertIcon/>
                                {t('annuaire_important_warning')}
                            </Alert>
                            <Box pt={4}>
                                <Text>
                                    {t('annuaire_connection_instruction', {id: user.id})}
                                </Text>
                                <Text>
                                    {t('annuaire_renew_password')}
                                </Text>
                                <Text>
                                    {t('annuaire_need_help')}
                                    <Text as={'span'} textColor={'primary'}>
                                        <a href={`mailto://${t('annuaire_need_help_email')}`}>
                                            {t('annuaire_need_help_email')}
                                        </a>
                                    </Text>.
                                </Text>
                            </Box>
                            <a href={'https://annuaire-sophrologues.fr/my-account'}>
                                <HStack backgroundColor={'blue.400'} rounded={'sm'} textColor={'white'} w={'40'} h={'10'}
                                        align={'center'} justify={'center'}>
                                    <FiExternalLink/>
                                    <Text>
                                        {t('annuaire_redirection_button')}
                                    </Text>
                                </HStack>
                            </a>
                        </> : <>
                            <VStack align={'start'} border={'1px'} borderColor={'gray.200'} spacing={2} p={4} rounded={'sm'}>
                                <Text>{t('annuaire_create_procedure_title')}</Text>
                                <Text>1/ {t('annuaire_create_first_step')}</Text>
                                <Text>2/ {t('annuaire_create_second_step')}</Text>
                                <Text>3/ {t('annuaire_create_third_step', {email: user?.email})}</Text>
                                <Text>4/ {t('annuaire_create_fourth_step')}</Text>
                                <Text>5/ {t('annuaire_create_fifth_step')}</Text>
                                <Text>
                                    {t('annuaire_need_help')}
                                    <Text as={'span'} textColor={'primary'}>
                                        <a href={`mailto://${t('annuaire_need_help_email')}`}>
                                            {t('annuaire_need_help_email')}
                                        </a>
                                    </Text>.
                                </Text>
                            </VStack>
                            <HStack align={'start'}>
                                <Checkbox isChecked={checked} colorScheme={'green'} onChange={handleCheck} mt={1} />
                                <Text>
                                    {t('annuaire_create_checkbox_description')}
                                </Text>
                            </HStack>
                            <Button isDisabled={!checked} onClick={handleCreate}>
                                {t('annuaire_create_button_label')}
                            </Button>
                        </>}
                    </VStack> : <Box>
                        <Alert status={'error'}>
                            <AlertIcon />
                            {t('annuaire_no_subscription_error')}
                        </Alert>
                    </Box>}
                    <Flex justify={'end'} w={'100%'}>
                        <Image
                            height={95}
                            width={250}
                            src={"/chambre.png"}
                            alt={'logo-chambre'}
                            style={{maxHeight: '100%', width: 'auto'}}
                        />
                    </Flex>
                </VStack>}
            </Card>
        </Layout>
    )
}

export default BookPage