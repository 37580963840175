import * as React from "react";
import {asField} from "../../config/as";
import IconField from "../IconField";

export function EmailField({onChange = () => {}, ...props}: EmailFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({target: {value: e.target?.value?.toLowerCase()}})
    return (
        <IconField icon={'email'} type={'email'} onChange={handleChange} {...props} />
    )
}

export interface EmailFieldProps extends asField {}

export default EmailField;