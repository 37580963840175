import * as React from "react";
import {CalendarIcon, EmailIcon, PhoneIcon, SmallAddIcon, StarIcon} from "@chakra-ui/icons";
import {Box, Flex, Icon, Input, InputGroup, InputLeftAddon, Select, Text} from "@chakra-ui/react";
import {BsFillPersonFill} from "react-icons/bs";
import {FaBuilding, FaCity, FaSchool} from "react-icons/fa";
import {IoEarth} from "react-icons/io5";
import {MdLink, MdLocationOn} from "react-icons/md";
import {withOptions, withPattern, withType} from "../config/withs";
import {asField} from "../config/as";
import FormattedInput from "./FormattedInput";

const icons = {
    'add': SmallAddIcon,
    'building': FaBuilding,
    'calendar': CalendarIcon,
    'city': FaCity,
    'earth': IoEarth,
    'email': EmailIcon,
    'link': MdLink,
    'location': MdLocationOn,
    'person': BsFillPersonFill,
    'phone': PhoneIcon,
    'school': FaSchool,
    'star': StarIcon
}

export function Field({error, format, icon, onChange = () => {}, options, isDisabled, title, required, value, ...props}: IconFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {onChange(e)}
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {onChange(e)}
    return (
        <Flex direction={'column'} w={'100%'}>
            {title && <Text fontSize={'xs'}>{!!required ? `${title} *` : title}</Text>}
            <InputGroup>
                <InputLeftAddon><Icon as={icons[icon]}/></InputLeftAddon>
                {format ?
                    <FormattedInput
                        format={format}
                        error={error}
                        isDisabled={isDisabled}
                        onChange={handleChange}
                        value={value}
                        {...props}
                    />
                    : options ?
                        <Select
                            onChange={handleSelectChange}
                            borderLeftRadius={0}
                            focusBorderColor={error ? 'red.500' : 'primary'}
                            _hover={{borderColor: error ? 'red.600' : 'gray.300'}}
                            borderColor={error ? 'red.500' : 'gray.200'}
                            disabled={isDisabled}
                            value={value}
                            {...props}
                        >
                            {options.map(({name, value}) => (
                                <option key={`school-${value}`} value={value}>{name}</option>
                            ))}
                        </Select>
                        : <Input
                            onChange={handleChange}
                            focusBorderColor={error ? 'red.500' : 'primary'}
                            _hover={{borderColor: error ? 'red.600' : 'gray.300'}}
                            borderColor={error ? 'red.500' : 'gray.200'}
                            disabled={isDisabled}
                            value={value ? value : ''}
                            {...props}
                        />
                }
            </InputGroup>
            {error && error.show && <Text fontSize={'xs'} color={'red.500'}>{error.content}</Text>}
        </Flex>
    )
}

export function IconField({label, required, ...props}: IconFieldProps) {
    return label ? (
        <Flex align={'center'} w={'100%'}>
            <Text as={'span'} fontSize={'sm'} w={'40%'}>{!!required ? `${label} *` : label}</Text>
            <Box ml={4} w={'60%'}>
                <Field required={required} {...props} />
            </Box>
        </Flex>
    ) : <Field required={required} {...props} />
}

export interface IconFieldProps extends asField, withPattern, withType, withOptions {
    icon: 'add' | 'building' | 'calendar' | 'city' | 'earth' | 'email' | 'link' | 'location' | 'person' | 'phone' |
        'school' | 'star'
    isCharacterAllowed?: any;
}

export default IconField;