import * as React from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Card,
    CardBody,
    CloseButton,
    Container,
    Divider,
    Flex,
    Heading,
    Image,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import RegisterForm from "../components/forms/RegisterForm";
import {camelToSnake, snakeToCamel} from "../utils/cases";


export function RegisterPage() {
    const { t } = useTranslation('auth');
    const { t: commonT } = useTranslation('common');
    const {
        isOpen: alertIsVisible,
        onClose
    } = useDisclosure({ defaultIsOpen: true })
    const toast = useToast()
    const [state, setState] = React.useState({errors: {}})

    React.useEffect(() => {
        if (window.localStorage.getItem(String(process.env['REACT_APP_SESSION_COOKIE'])))
            window.location.replace('/profile')
    }, [])

    const handleRegister = async (body: any) => {
        const id = toast({description: t('auth_toast_register_loading'), status: "loading", isClosable: false})

        fetch(`${process.env['REACT_APP_API_URL']}/register/`, {
            method: 'POST',
            body: JSON.stringify(camelToSnake(body)),
            headers: {'Content-Type': 'application/json'},
            credentials: "include"
        }).then(async (resp) => {
            const respData = await resp.json();
            if (!resp.ok) {
                toast.update(id, {description: t('auth_toast_register_error'), isClosable: true, status: "error"});
                setState({errors: {...snakeToCamel(respData)}});
            } else {
                window.localStorage.setItem(process.env['REACT_APP_SESSION_COOKIE'] as string, respData.token)
                toast.update(id, {description: t('auth_toast_register_success'), isClosable: true, status: "success"})
                window.location.assign('/profile')
            }
        }).catch(async () => {
            toast.update(id, {description: t('auth_toast_register_error'), isClosable: true, status: "error"})
        })
    };

    return (
        <Flex align={'center'} justify={'center'} py={'100px'}>
            <Box position={'absolute'} top={'4'} left={'4'} zIndex={0}>
                <Image
                    src={"/logo-chambre.png"}
                    alt={"Logo"}
                    w={'459px'}
                    h={'489px'}
                    style={{maxWidth: '100%', height: 'auto'}}
                />
            </Box>
            <Card
                border={'1px'}
                borderColor={'gray.200'}
                rounded={'md'}
                boxShadow={'md'}
            >
                <CardBody>
                    <VStack spacing={'20px'}>
                        <Heading as={'h1'} fontSize={'xl'} fontWeight={'bold'} textColor={'primary'}>
                            {t('auth_register_form_title')}
                        </Heading>
                        <Container fontSize={'sm'} alignContent={'center'} maxW={'container.lg'}>
                            {t('auth_register_form_login_text')}
                            <Text ml={'4px'} textColor={'primary'} _hover={{textDecoration: 'underline'}} as={'span'}>
                                <a href={'/auth/login'}>{commonT('button_login_label')}.</a>
                            </Text>
                        </Container>
                        {alertIsVisible && <Alert status='info' w={'90%'}>
                            <AlertIcon/>
                            <Box w={'100%'}>
                                <AlertTitle fontSize={'sm'}>
                                    <Text>{commonT('alert_info_label')}</Text>
                                </AlertTitle>
                                <AlertDescription fontSize={'xs'}>
                                    <Container maxW={'container.xl'} p={0}>
                                        {t('auth_register_alert_text')}
                                    </Container>
                                </AlertDescription>
                            </Box>
                            <CloseButton
                                alignSelf='flex-start'
                                position='relative'
                                right={-1}
                                top={-1}
                                onClick={onClose}
                            />
                        </Alert>}
                        <Divider borderColor={'gray.200'} />
                        <RegisterForm errors={state.errors} onSubmit={handleRegister} />
                    </VStack>
                </CardBody>
            </Card>
        </Flex>
    )
}

export interface RegisterPageProps {}

export default RegisterPage;