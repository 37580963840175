import * as React from "react";
import {PatternFormat} from "react-number-format";
import {asField} from "../config/as";

export function FormattedInput({error, format, isDisabled, onChange = () => {}, mask = '-', value, ...props}: FormattedInputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const str = String(e.target.value).replaceAll(' ', '').replaceAll('-', '')
        onChange({target: {value: str}})
    }
    const [focused, setFocused] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const handleFocus = () => setFocused(true)
    const handleBlur = () => setFocused(false)

    React.useEffect(() => {
        setShow(true)
    }, [setShow])

    return show ? (
        <PatternFormat
            allowEmptyFormatting
            mask={mask}
            format={format}
            style={{
                borderRadius: "var(--chakra-radii-md)",
                borderBlockWidth: focused ? '2px' : '1px',
                borderInlineWidth: focused ? '2px' : '1px',
                borderColor: error ? 'var(--chakra-colors-red-500' : focused ? 'var(--chakra-colors-primary)' : 'inherit',
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderWidth: "1px",
                outlineWidth: "2px",
                outlineStyle: "solid",
                outlineColor: 'transparent',
                paddingLeft: "1rem",
                transitionDuration: 'var(--chakra-transition-duration-normal)',
                transitionProperty: 'var(--chakra-transition-property-common)',
                width: "100%"
            }}
            disabled={isDisabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
        />
    ) : <></>
}

export interface FormattedInputProps extends asField {
    format: string;
    mask?: string;
}

export default FormattedInput