import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
    .use(initReactI18next)
    .use(resourcesToBackend((language: any, namespace: any) => import(`./locales/${language}/${namespace}.json`)))
    .init({
        debug: false,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n;