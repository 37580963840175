import {Box, Flex, HStack, Image, Spacer} from "@chakra-ui/react";
import {withCurrent, withItems} from "../config/withs";
import {useTranslation} from "react-i18next";

export function Header({items = [], current = -1, onDisconnect}: HeaderProps) {
    const handleDisconnect = () => onDisconnect && onDisconnect();
    const {t} = useTranslation('common')
    return (
        <HStack
            h={'90px'}
            w={'100%'}
            justifyContent={'center'}
            borderBottom={'1px'}
            borderColor={'gray.200'}
            boxShadow={'sm'}
        >
            <Flex w={'80%'} h={'100%'} align={'center'}>
                <Image src={'/logo-chambre.png'} alt={'Logo'} w={'40px'} height={'40px'}/>
                <Spacer />
                <HStack h={'100%'} align={'end'} spacing={6}>
                    {items.map((item, id) => {return (
                        <Box
                            key={`navitem-${id}`}
                            pb={'10px'}
                            textColor={current === id ? 'primary' : 'default'}
                            borderBottom={'2px'}
                            borderColor={current === id ? 'primary' : 'transparent'}
                            _hover={{ textColor: 'primary', borderColor: 'primary' }}
                        >
                            <a href={item.url}>
                                {item.name}
                            </a>
                        </Box>
                    )})}
                    <Box
                         pb={'10px'}
                         borderBottom={'2px'}
                         borderColor={'transparent'}
                         _hover={{ textColor: 'primary', borderColor: 'primary', cursor: 'pointer'}}
                         onClick={handleDisconnect}
                    >
                        {t('button_disconnect_label')}
                    </Box>
                </HStack>
            </Flex>
        </HStack>
    )
}

export interface HeaderProps extends withItems, withCurrent {
    onDisconnect?: Function;
}

export default Header