import {asField} from "../../config/as";
import IconField from "../IconField";

export function DateField({value, ...props}: DateFieldProps) {
    const date = String(value).indexOf("T") !== -1 ? String(value).substring(0, String(value).indexOf("T")) : value
    return <IconField icon={'calendar'} type={'date'} value={date} {...props} />
}

export interface DateFieldProps extends asField {}

export default DateField;