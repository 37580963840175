import {Button, Spacer, VStack} from "@chakra-ui/react";
import {asForm} from "../../config/as";
import {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {EmailField, PasswordField} from "../fields";

export function LoginForm({onSubmit = () => {}, initialState}: LoginFormProps) {
    const [state, setState] = useState<LoginFormState>(initialState)
    const {t} = useTranslation('common')

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setState(
        {...state, email: e.target.value}
    );
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setState(
        {...state, password: e.target.value}
    );

    const handleSubmit = () => {onSubmit(state)};

    return (
        <VStack spacing={'10px'}>
            <EmailField
                placeholder={t('field_email_placeholder')}
                onChange={handleEmailChange}
                value={state ? state.email : ''}
            />
            <PasswordField
                placeholder={t('field_password_placeholder')}
                onChange={handlePasswordChange}
                value={state ? state.password : ''}
            />
            <Spacer />
            <Button
                backgroundColor={'primary'}
                _hover={{
                    backgroundColor: 'darker'
                }}
                textColor={'white'}
                size={'sm'}
                onClick={handleSubmit}
            >
                {t('button_login_label')}
            </Button>
        </VStack>
    )
}

export interface LoginFormState {
    email: string;
    password: string;
}

export interface LoginFormProps extends asForm {}

export default LoginForm;