import {Button as ChakraButton, ButtonProps as ChakraButtonProps} from '@chakra-ui/react';
import {withLabel} from "../config/withs";

export function Button({label = "", children, ...props}: ButtonProps) {
    return (
        <ChakraButton
            backgroundColor={'primary'}
            _hover={{
                backgroundColor: 'darker'
            }}
            textColor={'white'}
            fontWeight={'normal'}
            size={'sm'}
            {...props}
        >
            {children || label}
        </ChakraButton>
    )
}

export interface ButtonProps extends ChakraButtonProps, withLabel {
}

export default Button