import {asField} from "../../config/as";
import IconField from "../IconField";

export function CountryField(props: CountryFieldProps) {
    return (
        <IconField icon={'earth'} {...props} />
    )
}

export interface CountryFieldProps extends asField {}

export default CountryField;