import * as React from "react";
import {Heading, StackDivider, useToast, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {EditEmailForm, EditPasswordForm} from "../forms";
import {withSetUserRequired, withUserRequired} from "../../config/withs";
import {authenticatedRequest} from "../../utils/authenticatedRequest";
import {camelToSnake, snakeToCamel} from "../../utils/cases";

export function Ids({user, setUser}: IdsProps) {
    const {t} = useTranslation('profile')
    const {t:commonT} = useTranslation('common')
    const [state, setState] = React.useState({errors: {}})
    const toast = useToast()
    const handleEmailSubmit = async ({email}: any) => {
        const id = toast({status: "loading", description: commonT('toast_loading_title'), isClosable: false})
        const snakeBody = camelToSnake({...user, email})
        const resp = await authenticatedRequest({
            url: `${process.env['REACT_APP_API_URL']}/users/${user.id}/`,
            fetchProps: {
                method: 'PUT',
                body: JSON.stringify(snakeBody),
                headers: {'Content-Type': 'application/json'}
        }}).catch(() => {
            toast.update(id, {status: "error", description: commonT('toast_error_title'), isClosable: true})
        })
        const data = await resp?.json()
        if (!data || resp?.status !== 200) {
            toast.update(id, {status: "error", description: commonT('toast_error_title'), isClosable: true})
            setState({...state, errors: data})
        } else {
            setState({...state})
            setUser(snakeToCamel(data))
            toast.update(id, {status: "success", description: t('profile_toast_ids_success_title'), isClosable: true})
        }
    }
    const handlePasswordSubmit = async ({password}: any) => {
        const id = toast({status: "loading", description: commonT('toast_loading_title'), isClosable: false})
        const snakeBody = camelToSnake({...user, password})
        const resp = await authenticatedRequest({
            url: `${process.env['REACT_APP_API_URL']}/users/${user.id}/`,
            fetchProps: {
                method: 'PUT',
                body: JSON.stringify(snakeBody),
                headers: {'Content-Type': 'application/json'}
        }}).catch(() => {
            toast.update(id, {status: "error", description: commonT('toast_error_title'), isClosable: true})
        })
        const data = await resp?.json()
        if (data) {
            setUser(snakeToCamel(data))
            toast.update(id, {status: "success", description: t('profile_toast_ids_success_title'), isClosable: true})
        }
    }

    return (
        <VStack divider={<StackDivider />} w={'100%'} spacing={6}>
            <Heading fontSize={'md'} textColor={'primary'}>{t('profile_ids_form_title')}</Heading>
            <EditEmailForm user={user} onSubmit={handleEmailSubmit} />
            <EditPasswordForm user={user} onSubmit={handlePasswordSubmit} />
        </VStack>
    )
}

export interface IdsProps extends withUserRequired, withSetUserRequired {}

export default Ids