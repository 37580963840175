import {CustomLayoutProps} from "../../config/types";
import {VStack} from "@chakra-ui/react";

export function CenteredLayout({title = "", children}: CustomLayoutProps) {
    return (
        <VStack alignContent={'center'} pt={'25px'}>
            {children}
        </VStack>
    )
}

export default CenteredLayout