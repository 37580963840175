export function snakeToCamel(obj: any) {
    let data = Object()
    if (typeof(obj) === 'string') return obj
    Object.keys(obj).forEach((key) => {
        data[key.replace(/(_[a-z1-9])/g, letter => letter
            .toUpperCase().replace('_', ''))] = Array.isArray(obj[key]) ?
            obj[key].map(snakeToCamel) : typeof(obj[key]) === 'object' && obj[key] !== null ?
                snakeToCamel(obj[key]) : obj[key]
    })
    return data;
}

export function camelToSnake(obj: any) {
    let data = Object()
    Object.keys(obj).forEach((key) => {
        data[key.replace(/[A-Z1-9]/g, letter => `_${letter.toLowerCase()}`)] =
            Array.isArray(obj[key]) ? obj[key].map(camelToSnake) :
            typeof(obj[key]) === 'object' && obj[key] !== null ? camelToSnake(obj[key]) : obj[key]
    })
    return data
}