import {Subscription, User} from "../config/types";
import {authenticatedRequest} from "../utils/authenticatedRequest";
import {camelToSnake, snakeToCamel} from "../utils/cases";

export function hasActiveSubscription(user: User): boolean {
    const activeSubscriptions = user.subscriptions.filter((
        {
            endDate,
            product
        }) => product.name.toLowerCase().includes('cotisation') && endDate && new Date(endDate) > new Date()
    )
    return Boolean(activeSubscriptions.length)
}

export async function createSubscription(body: any): Promise<Subscription> {
    return new Promise((res, rej) => {
        authenticatedRequest({
            url:`${process.env['REACT_APP_API_URL']}/subscriptions/`,
            fetchProps: {
                body: JSON.stringify(camelToSnake(body)),
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            }
        }).then((resp) => {
            if (!resp.ok) return new Promise((_, rej) => rej('error_internal_server_error'))
            resp.json().then((data) => res(snakeToCamel(data)))
        }).catch((e) => {
            rej(e)
        })
    })
}

export async function getSubscription(id: number): Promise<Subscription> {
    return new Promise((res, rej) => {
        authenticatedRequest({url: `${process.env['REACT_APP_API_URL']}/subscriptions/${id}`})
            .then((resp) => {
                if (!resp.ok) return new Promise((_, rej) => rej('error_internal_server_error'))
                resp.json().then((data) => res(snakeToCamel(data)))
            }).catch((e) => {
                rej(e)
        })
    })
}

export async function deleteSubscription(id: number): Promise<void> {
    await authenticatedRequest({
        url: `${process.env['REACT_APP_API_URL']}/subscriptions/${id}/`,
        fetchProps: {
            method: 'DELETE',
        }
    })
}
