import * as React from "react";
import {Box, Button, Flex, Input, InputGroup, InputLeftAddon, InputRightElement, Text} from "@chakra-ui/react";
import {asField} from "../../config/as";
import {LockIcon, ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

function Field({error, onChange = () => {}, title, required, value, ...props}: PasswordFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show)

    return (
        <Flex direction={'column'} w={'100%'}>
            {/*<Text fontSize={'xs'}>{required ? `${title} *` : title}</Text>*/}
            <InputGroup>
                <InputLeftAddon><LockIcon /></InputLeftAddon>
                <Input
                    onChange={handleChange}
                    focusBorderColor={error ? 'red.500' : 'primary'}
                    borderColor={error ? 'red.500' : 'gray.200'}
                    type={show ? 'text' : 'password'}
                    value={value ? value : ''}
                    {...props}
                />
                <InputRightElement width={'4.5rem'} mr={'-10px'}>
                    <Button h={'1.75rem'} size={'sm'} onClick={handleClick}>
                        {show ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                </InputRightElement>
            </InputGroup>
            {error && error.show && <Text fontSize={'xs'} color={'red.500'}>{error.content}</Text>}
        </Flex>
    )
}

export function PasswordFieldChange({label, required, ...props}: PasswordFieldProps) {
    return label ? (
        <Flex align={'center'} w={'100%'}>
            <Text as={'span'} fontSize={'sm'} w={'40%'}>{required ? `${label} *` : label}</Text>
            <Box ml={4} w={'60%'}>
                <Field required={required} {...props} />
            </Box>
        </Flex>
    ) : <Field required={required} {...props} />
}



export interface PasswordFieldProps extends asField {}

export default PasswordFieldChange;
