import * as React from 'react';
import {asField} from "../../config/as";
import IconField from "../IconField";

export function NirppField({onChange = () => {}, ...props}: NirppFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.value?.length > 15)
            e.target.value = e.target.value.substring(0, 15);
        onChange(e);
    }
    return (
        <IconField icon={'star'} onChange={handleChange} {...props} />
    )
}

export interface NirppFieldProps extends asField {}

export default NirppField;