import * as React from "react";
import {
    Alert,
    Box,
    Button as ChakraButton,
    Container,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack
} from "@chakra-ui/react";
import {AiFillCreditCard, AiFillPrinter} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import {withProductsRequired, withUserRequired} from "../../config/withs";
import {generateParameters, generateSignature} from "../../services/systempay";
import {Product} from "../../config/types";
import {fetchSubscriptionBill, fetchSubscriptionCertificate} from "../../services/documents";
import Button from "../Button";
import ChooseField from "../ChooseField";
import {hasActiveSubscription} from "../../services/subscriptions";


export function Certificates({products, user}: CertificatesProps) {
    const certisophroProducts = products.filter(({name}) => name.toLowerCase().includes("certisophro"))
    const toast = useToast()
    const [chosen, setChosen] = React.useState<Product>(certisophroProducts[0])
    const {t, i18n} = useTranslation('profile')
    const subscriptions = user.subscriptions?.filter(
        ({product}) => !!certisophroProducts.find(({id}) => product.id === id)
    )

    const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChosen(certisophroProducts.find(({id}) => String(id) === String(e.target.value)) as Product)
    }
    const parameters = generateParameters(user, chosen)
    const systempaySignature = generateSignature(process.env['REACT_APP_SYSTEMPAY_SELLER_CERTIFICATE'] as string, parameters)

    return user.siret ? hasActiveSubscription(user) ? (
        <VStack w={'100%'} spacing={8}>
            <Heading fontSize={'md'} textColor={'primary'}>{t('profile_certificate_heading')}</Heading>
            <HStack fontSize={'xs'} spacing={24}>
                <Alert status={"success"}>
                    <Container maxW={'container.sm'}>
                        <Text>{t('profile_certificate_info_text_1')}</Text>
                        <Text>{t('profile_certificate_info_text_2')}</Text>
                        <Text>
                            {t('profile_certificate_info_pre_link') + " "}
                            <a
                                href={"https://www.chambre-syndicale-sophrologie.fr/certisophro/"}
                                style={{color: "var(--chakra-colors-blue-500)"}}
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                https://www.chambre-syndicale-sophrologie.fr/certisophro/
                            </a>.
                        </Text>
                    </Container>
                </Alert>
                <Image
                    src={"/logo-avec-cadre-certisophro-600x600-1-400x400.jpg"}
                    alt={"CertiSoph"}
                    w={'200px'}
                    h={'200px'}
                />
            </HStack>
            <TableContainer w={'100%'}>
                <Table size={'sm'} variant={'simple'}>
                    <Thead>
                        <Tr>
                            <Th>{t('profile_certificate_table_head_created_at')}</Th>
                            <Th>{t('profile_certificate_table_head_subscription_type')}</Th>
                            <Th>{t('profile_certificate_table_head_payment_mean')}</Th>
                            <Th>{t('profile_certificate_table_head_amount')}</Th>
                            <Th>{t('profile_certificate_table_head_bill')}</Th>
                            <Th>{t('profile_certificate_table_head_status')}</Th>
                            <Th>{t('profile_certificate_table_head_start_date')}</Th>
                            <Th>{t('profile_certificate_table_head_end_date')}</Th>
                            <Th>{t('profile_certificate_table_head_prints')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions?.map(({
                            endDate,
                            id,
                            amount,
                            paymentMean,
                            product,
                            startDate,
                            createdAt,
                            status,
                        }) => {
                            const handleDownload = (type: string) => (
                                async function () {
                                    const toastId = toast({status: "loading", description: t('profile_toast_loading_document_title')})
                                    const file = type === "bill" ? await fetchSubscriptionBill(id) : await fetchSubscriptionCertificate(id);
                                    if (!file) {
                                        toast.update(toastId, {
                                            status: "error", description: t('profile_toast_error_no_document'), isClosable: true
                                        })
                                    } else {
                                        toast.close(toastId);
                                        const aElement = document.createElement('a');
                                        aElement.setAttribute(
                                            'download',
                                            `${type === "bill" ? t("profile_document_bill") : t("profile_document_certificate")} ${user?.firstName} ${user?.lastName}.pdf`
                                        )
                                        aElement.href = file;
                                        aElement.setAttribute('target', '_blank');
                                        aElement.click();
                                        URL.revokeObjectURL(file);
                                    }
                            })
                            return <Tr key={`certificate-${id}`} fontSize={'sm'}>
                                <Td>{new Date(createdAt).toLocaleDateString(i18n.language)}</Td>
                                <Td>{product?.name}</Td>
                                <Td>{paymentMean}</Td>
                                <Td>{amount} €</Td>
                                <Td>
                                    <ChakraButton onClick={handleDownload('bill')} variant={'unstyled'}>
                                        <Flex align={'center'} textColor={'primary'}>
                                            <Icon as={AiFillPrinter} mr={1}/>
                                            <Text _hover={{textDecoration: 'underline'}}>
                                                {t('profile_certificate_table_bill_link')}
                                            </Text>
                                        </Flex>
                                    </ChakraButton>
                                </Td>
                                <Td>{status ? t(`profile_certificate_table_${status.toLowerCase().replace(' ', '_')}`) : "---"} </Td>
                                <Td>{startDate ? new Date(startDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                <Td>{endDate ? new Date(endDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                <Td>
                                    {product.documents.find(({type}) => type.code === "certificate") && status === "ACCEPTE" ?
                                        <ChakraButton onClick={handleDownload('certificate')} variant={'unstyled'}>
                                            <Flex align={'center'} textColor={'primary'}>
                                                <Icon as={AiFillPrinter} mr={1}/>
                                                <Text _hover={{textDecoration: 'underline'}}>
                                                    {t('profile_certificate_table_prints_link')}
                                                </Text>
                                            </Flex>
                                        </ChakraButton>
                                        : <Text>---</Text>
                                    }
                                </Td>
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <VStack align={'start'} w={'100%'}>
                <Flex justify={'start'} w={'100%'}>
                    <VStack border={'1px'} borderColor={'gray.200'} p={4} rounded={'sm'} spacing={4}>
                        <Text>{t('profile_certificate_choice')}</Text>
                        <ChooseField
                            direction={"column"}
                            options={certisophroProducts.map(({id, name}) => ({
                                name,
                                value: id
                            }))}
                            onChange={handleProductChange}
                            value={chosen.id}
                        />
                    </VStack>
                    <VStack border={'1px'} borderColor={'gray.200'} ml={8} p={4} rounded={'sm'} spacing={4}>
                        <Text>{t('profile_subscription_title')}</Text>
                        <Box w={'100%'}>
                            <Text fontSize={'xs'}>- {t('profile_subscription_instant_transaction')}</Text>
                            <Text fontSize={'xs'}>- {t('profile_subscription_secured_transaction')}</Text>
                        </Box>
                        <form method={'POST'} action={'https://paiement.systempay.fr/vads-payment/'}>
                            {Object.keys(parameters).map((key) => (
                                <input key={`sp-${key}`} type={"hidden"} name={key} value={(parameters as any)[key]} />
                            ))}
                            <input type={"hidden"} name={"signature"} value={systempaySignature} />
                            <Button leftIcon={<AiFillCreditCard />} type={"submit"}>
                                {t('profile_button_pay')}
                            </Button>
                        </form>
                    </VStack>
                </Flex>
            </VStack>
        </VStack>
    ) : <Alert status={"error"}>{t('profile_certificate_no_subscription')}</Alert>
        : <Alert status={"error"}>{t('profile_certificate_alert_no_siret')}</Alert>
}

export interface CertificatesProps extends withProductsRequired, withUserRequired {}

export default Certificates