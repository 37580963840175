import {useToast} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import Header from './Header'
import CenteredLayout from "./layouts/CenteredLayout";
import NavbarCenteredLayout from "./layouts/NavbarCenteredLayout";
import {withChildren} from "../config/withs";

const mappings = {
    'centered': CenteredLayout,
    'navcentered': NavbarCenteredLayout
}

export function Layout({type = 'centered', children}: LayoutProps) {
    const toast = useToast();
    const {t} = useTranslation('common')
    const Component = mappings[type];
    const navItems = [
        {name: 'Mon Espace', url: `/profile`},
    ]
    const currentNavItem = navItems.findIndex((item) => window?.location.pathname === item.url)
    const handleDisconnect = async () => {
        const id = toast({
            title: t('toast_logout_loading_title'),
            status: "loading",
            isClosable: false,
        })
        const token = window.localStorage.getItem(process.env['REACT_APP_SESSION_COOKIE'] as string)
        if (!token) {
            toast.update(id, {
                status: "error",
                title: t('toast_error_title'),
                description: t('toast_logout_no_token_error')
            })
        } else {
            window.localStorage.removeItem(process.env['REACT_APP_SESSION_COOKIE'] as string)
            toast.update(id, {
                description: t('toast_logout_description'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            window.location.assign('/auth/login')
        }
    }

    return (
        <>
            <Header items={navItems} current={currentNavItem} onDisconnect={handleDisconnect}/>
            <Component>
                {children}
            </Component>
        </>
    )
}

export interface LayoutProps extends withChildren {
    type?: 'centered' | 'navcentered';
}

export default Layout