import {Product, User} from "../config/types";
import sha1 from "sha1";

export function generateSignature(certificate: string, config: object) {
    const values = Object.keys(config).sort().map(
        (key) => (config as any)[key]
    )
    const str = values.join('+') + `+${certificate}`
    return sha1(str)
}

export function generateTransId() {
    const timestamp = Date.now()
    const basetime = new Date()
    basetime.setHours(0)
    basetime.setMinutes(0)
    basetime.setSeconds(0)
    basetime.setMilliseconds(0)
    const delta = timestamp - basetime.getTime()
    return `${(delta / 100).toFixed()}`
}

export function generateParameters(user: User, product?: Product): any {
    if (!product) return {}
    return {
        vads_site_id: process.env['REACT_APP_SYSTEMPAY_ID'] as string,
        vads_ctx_mode: process.env['REACT_APP_SYSTEMPAY_ENVIRONMENT'] as string,
        vads_action_mode: 'INTERACTIVE',
        vads_page_action: 'PAYMENT',
        vads_version: 'V2',
        vads_payment_config: 'SINGLE',
        vads_capture_delay: '0',
        vads_validation_mode: '0',

        vads_order_id: (String(user.id) + '-' + String(product.id)),

        vads_cust_email: user.email,
        vads_cust_id: user.id,
        vads_cust_title: user.sex === 'M' ? 'Monsieur' : 'Madame',
        vads_cust_first_name: user.firstName,
        vads_cust_last_name: user.lastName,
        vads_cust_cell_phone: user.mobilePhoneNumber,
        vads_cust_address: user.addressLine1 + ' ' + user.addressLine2,
        vads_cust_zip: user.zipCode,
        vads_cust_city: user.city,

        vads_trans_id: generateTransId(),
        vads_trans_date: new Date().toISOString().split('.')[0].replace(/\D/gm, ''),
        vads_amount: String(product.price * 100),
        vads_currency: '978',
        vads_nb_products: '1',

        vads_url_success: new URL('/payment/success', process.env['REACT_APP_HOST_URL'] as string),
        vads_url_refused: new URL('/payment/refused', process.env['REACT_APP_HOST_URL'] as string),
        vads_url_cancel: new URL('/payment/cancel', process.env['REACT_APP_HOST_URL'] as string),
        vads_url_error: new URL('/payment/error', process.env['REACT_APP_HOST_URL'] as string),

        vads_language: 'fr',
        vads_redirect_success_timeout: '4',
    }
}