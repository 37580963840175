import * as React from "react";
import {withDirection, withOptions} from "../config/withs";
import {Box, Checkbox, Flex, HStack, Text, VStack} from "@chakra-ui/react";
import {asField} from "../config/as";

export function Choose({direction = "row", options = [], onChange = () => {}, error, id, isDisabled, value}: ChooseFieldProps) {
    const [state, setState] = React.useState<ChooseFieldState>({
        checked: undefined,
        checkedId: options?.findIndex(({value: value2}) => value === value2)
    })
    const Stack = direction === "row" ? HStack : VStack;
    const isBoxChecked = (id: Number) => state.checkedId === id;
    return (
        <Flex direction={'column'} w={'100%'}>
            <Stack align={'start'} justify={'space-evenly'} spacing={'10px'}>
                {options.map((item, index) => {
                const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                        setState({checked: e.target, checkedId: index});
                        onChange({ ...e, target: { ...e.target, id, value: item.value }});
                    }
                }
                return (
                    <Checkbox
                        key={`choose-option-${index}`}
                        onChange={handleChange}
                        isChecked={isBoxChecked(index)}
                        isDisabled={isDisabled}
                        colorScheme={error ? 'red' : 'green'}
                    >
                        {item.name}
                    </Checkbox>
                )})}
            </Stack>
            {error && error.show && <Text fontSize={'xs'} pl={'10px'} color={'red.500'}>{error.content}</Text>}
        </Flex>

    )
}

export function ChooseField({label, required, ...props}: ChooseFieldProps) {
    return label ?
        <Flex align={'center'}  w={'100%'}>
            <Text as={'span'} fontSize={'sm'} w={'40%'}>{!!required ? `${label} *` : label}</Text>
            <Box w={'60%'}>
                <Choose required={required} {...props} />
            </Box>
        </Flex> :
        <Choose required={required} {...props} />
}

export interface ChooseFieldState {
    checked?: HTMLInputElement;
    checkedId: Number;
}

export interface ChooseFieldProps extends withDirection, withOptions, asField {
}

export default ChooseField;