import {
    Box,
    Flex,
    Divider,
    Image,
    Text,
    VStack,
    Container,
    Card, CardBody, Heading, useToast, Alert, AlertIcon
} from "@chakra-ui/react";
import {useTranslation} from 'react-i18next'
import LoginForm from '../components/forms/LoginForm'
import {useEffect, useState} from "react";

export function LoginPage() {
    const { t } = useTranslation('auth')
    const { t: commonT } = useTranslation('common')
    const toast = useToast();
    const [state, setState] = useState({errors: {}})

    useEffect(() => {
        if (window.localStorage.getItem(String(process.env['REACT_APP_SESSION_COOKIE'])))
            window.location.replace('/profile')
    }, [])

    const handleLogin = (body: any) => {
        const id = toast({
            description: t('auth_toast_login_loading'),
            status: "loading",
            isClosable: false
        })
        fetch(`${process.env['REACT_APP_API_URL']}/login/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {'Content-Type': 'application/json'},
        }).then(async (resp) => {
            const respData = await resp.json()
            if (!resp.ok) {
                if (Object.keys(respData).includes('banni_radie_errors')){
                    toast.update(id, {description: t('auth_login_banni_radie_error'), isClosable: true, status: "error"})
                } else{
                    toast.update(id, {description: t('auth_toast_login_error'), isClosable: true, status: "error"})
                }
                setState({...state, errors: respData})
            } else {
                toast.update(id, {description: t('auth_toast_login_success'), isClosable: true, status: "success"})
                window.localStorage.setItem(process.env['REACT_APP_SESSION_COOKIE'] as string, respData.token)
                await window.location.assign('/profile')
            }
        }).catch(() => {
            toast.update(id, {description: t('auth_toast_login_server_error'), isClosable: true, status: "error"})
        })
    }

    return (
        <Flex direction={'column'} h={'100vh'} w={'100vw'} align={'center'} justify={'center'}>
            <Box position={"absolute"} top={'4'} left={'4'} zIndex={0}>
                <Image
                    src={"/logo-chambre.png"}
                    alt={'Logo'}
                    w={'459px'}
                    h={'489px'}
                    style={{maxWidth: '100%', height: 'auto'}}
                />
            </Box>
            <Card
                border={'1px'}
                borderColor={'gray.200'}
                boxShadow={'md'}
                px={'12px'}
                py={'18px'}
                zIndex={10}
            >
                <CardBody>
                    <VStack spacing={'20px'}>
                        <Heading as={'h1'} fontSize={'xl'} fontWeight={'bold'} textColor={'primary'}>
                            {t('auth_login_form_title')}
                        </Heading>
                        <Container fontSize={'sm'} alignContent={'center'}>
                            {t('auth_login_form_register_text')}
                            <Text ml={'4px'} textColor={'primary'} _hover={{textDecoration: 'underline'}} as={'span'}>
                                <a href={'/auth/register'}>{commonT('button_register_label')}.</a>
                            </Text>
                        </Container>
                        <Alert status={"warning"} fontSize={'xs'}>
                            <AlertIcon />
                            {t('auth_login_new_password')}
                        </Alert>
                        <Divider borderColor={'gray.200'} />
                        {Object.keys(state.errors).includes('non_field_errors') &&
                            <Alert status={"error"} fontSize={'xs'}>
                                {t('auth_login_wrong_credentials_error')}
                            </Alert>
                        }
                        {Object.keys(state.errors).includes('banni_radie_errors') &&
                            <Alert status={"error"} fontSize={'xs'}>
                                {t('auth_login_banni_radie_error')}
                            </Alert>
                        }

                        <LoginForm onSubmit={handleLogin}/>
                        <Divider borderColor={'gray.200'} />
                        <Text fontSize={'sm'}>{t('auth_login_forgot_email_text')}.
                            <Text ml={'4px'} textColor={'primary'} _hover={{textDecoration: 'underline'}} as={'span'}>
                                <a href={'/auth/email-reset'}>
                                    {t('auth_login_forgot_email_link')}.
                                </a>
                            </Text>
                        </Text>
                        <Text fontSize={'sm'}>
                            {t('auth_login_forgot_password_text')}.
                            <Text ml={'4px'} textColor={'primary'} _hover={{textDecoration: 'underline'}} as={'span'}>
                                <a href={'/auth/password-reset'}>
                                    {t('auth_login_forgot_password_link')}.
                                </a>
                            </Text>
                        </Text>
                    </VStack>
                </CardBody>
            </Card>
        </Flex>
    )
}

export default LoginPage;