import {asField} from "../../config/as";
import IconField from "../IconField";


export function SiretField({...props}: SiretFieldProps) {
    return <IconField icon={'building'} format={'### ### ### #####'} {...props} />
}

export interface SiretFieldProps extends asField {}

export default SiretField