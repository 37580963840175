export function valideApeCode(str: string): boolean {
    const regexp = new RegExp('^([0-9]{4})([A-Za-z])$')
    return str.length === 0 || regexp.test(str)
}

export function validatePhoneNumber(str: string): boolean {
    const regexp = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')
    return str.length === 0 || regexp.test(str)
}

export function validatePassword(str: string): boolean {
    return str.length > 7
}

export function validateEmail(str: string): boolean {
    const regexp = new RegExp('^[\\w|.-]+@[\\w-]+\\.+[\\w-]{2,4}$', 'g')
    return regexp.test(str)
}

export function validateNirpp(str: string): boolean {
    const regexp = new RegExp('^[1-2][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$', 'g')
    const key = Number(str.substring(str.length - 2))
    let number = Number(str.replace(/[ABab]/, '0').substring(0, str.length - 2))
    if (str.toLowerCase().includes('a')) number -= 1000000
    if (str.toLowerCase().includes('b')) number -= 2000000
    return regexp.test(str) && str.length === 15 && (97 - number % 97) === key
}

export function validateDateOfBirth(str: string): boolean {
        const now = Date.now()
        const date = Date.parse(str)

        return !(str === "" || date >= now)
}

export function validateName(str: string): boolean {
    const regexp = new RegExp('^[a-zA-Z ]*$', 'i')
    return str.length === 0 || regexp.test(str)
}

export function validateNum(str: string): boolean {
    const regexp = new RegExp('[0-9]+', 'g')
    return str.length === 0 || regexp.test(str)
}

export function validateAlpha(str: string): boolean {
    const regexp = new RegExp('^([a-zA-Z\u0080-\u024F]+(?:. |-| |\'))*[a-zA-Z\u0080-\u024F]*$', 'gmi')
    return regexp.test(str)
}

export function validateZipcode(str: string): boolean {
    const regexp = new RegExp('^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$', 'i')
    return regexp.test(str) && str.length === 5
}

export function validateNotEmpty(str: string): boolean {
    return str.length !== 0
}

export function validateSiret(str: string): boolean {
    return str.length === 0 || str.length === 14
}

export function validateSex(str: string): boolean {
    const regexp = new RegExp('[FM]')
    return regexp.test(str)
}

export function validateWebsite(str: string): boolean {
    const regexp = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')
    return str.length === 0 || regexp.test(str)
}
