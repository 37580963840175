import {Box, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useLoaderData} from "react-router-dom";

export function CssDataPage() {
    const {t} = useTranslation('common')
    const {stats} = useLoaderData() as CssDataPageLoaderResult
    if (!stats) return <Text>{t('error_unavailable_statistics')}</Text>;
    return (
        <Box
            backgroundColor={'rgb(242, 242, 242)'}
            color={'#747474'}
            fontFamily={'Georgia, serif'}
            fontSize={'16px'}
            fontStyle={'normal'}
            fontWeight={'400'}
            h={'100vh'}
            letterSpacing={'0px'}
            lineHeight={'1.5'}
            p={4}
        >
            <Text>{t('data_title_with_date', {date: stats.current_date})}</Text>
            <UnorderedList>
                <ListItem>{t('data_user_total', {total: stats.total_users})}</ListItem>
                <ListItem>{t('data_insured_total', {total: stats.total_insurances})}</ListItem>
            </UnorderedList>
        </Box>
    )
}

export async function cssDataPageLoader() {
    try {
        const resp = await fetch(`${process.env["REACT_APP_API_URL"]}/analytics/css/`)
        const stats = await resp.json()
        return {stats}
    } catch (e) {
        return {
            props: {
                stats: null,
            }
        }
    }
}

export interface CssDataPageLoaderResult {
    stats: any;
}

export default CssDataPage