import {useEffect, useState} from "react";
import {User} from "../config/types";
import {fetchUser} from "../services/user";

export function useSession() {
    const [user, setUser] = useState<User | undefined>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | undefined>()

    useEffect(() => {
        fetchUser().then((user) => {
            if (!user) {
                setError('error_user_does_not_exist')
                setLoading(false)
                window.localStorage.removeItem(process.env['REACT_APP_SESSION_COOKIE'] as string)
                window.location.replace('/auth/login')
                return
            }
            setUser(user)
            setLoading(false)
        }).catch(() => {
            setError('error_user_does_not_exist')
            setLoading(false)
            window.localStorage.removeItem(process.env['REACT_APP_SESSION_COOKIE'] as string)
            window.location.replace('/auth/login')
        })
    }, [])
    return {user, setUser, loading, error}
}

export default useSession