import {Card, HStack, VStack} from "@chakra-ui/react";
import {CustomLayoutProps} from "../../config/types";
import Navbar from "../Navbar";

export function NavbarCenteredLayout({title = "", children}: CustomLayoutProps) {
    return (
        <VStack alignContent={'center'} pt={'25px'}>
            <HStack align={'start'} w={'70%'} spacing={10}>
                <Card w={'15%'} minW={'15%'}>
                    <Navbar />
                </Card>
                {children}
            </HStack>
        </VStack>
    )
}

export default NavbarCenteredLayout