import * as React from "react"
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react"
import theme from "./config/theme"
import {routes} from "./config/routes"

const router = createBrowserRouter(routes);

export const App = () => (
  <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider>
)
