import * as React from "react";
import {Box, Checkbox, Divider, Flex, UnorderedList, ListItem, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import ChooseField from "../ChooseField";
import OptionalField from "../fields/OptionalField";
import Button from "../Button";
import {withUser} from "../../config/withs";
import {asForm} from "../../config/as";
import {validateNotEmpty} from "../../utils/validators";
import {addError, removeError} from "../../utils/errors";


export function InsuranceForm({onSubmit = () => {}, user}: InsuranceFormProps) {
    const {t} = useTranslation('common')
    const {t: profileT} = useTranslation('profile')
    const [state, setState] = React.useState<InsuranceFormState>({valid: false, errors: {}})
    const [checks, setChecks] = React.useState<number[]>([])
    const options = [
        {name: t('field_choose_yes_label'), value: true},
        {name: t('field_choose_no_label'), value: false}
    ]

    const handleFieldChange = (field: string, required?: boolean, reasonField?: string) => {
        return function(e: any) {
            if (required && !validateNotEmpty(e.target.value)) {
                setState({...state, [field]: e.target.value, errors: addError(state.errors,  field, 'error_required_field')})
            } else if (reasonField && e.target.value === true) {
                setState({...state, [field]: e.target.value, errors: addError(state.errors, reasonField, 'error_required_field')})
            } else if (reasonField && e.target.value === false) {
                setState({...state, [field]: e.target.value, errors: removeError(state.errors, reasonField, 'error_required_field')})
            } else {
                setState({...state, [field]: e.target.value, errors: removeError(state.errors, field, 'error_required_field')})
            }
        }
    }
    const handleCheck = (id: number) => {
        return function(e: any) {
            if (e.target.checked) {
                setChecks([...checks, id])
            } else {
                setChecks(checks.filter((v) => v !== id))
            }
        }
    }
    const canSubmit = state.valid &&
        state.alreadyInsured !== undefined &&
        state.alreadyInsuredCancellation !== undefined &&
        state.alreadyInsuredRefusedRenew !== undefined &&
        state.alreadyInsuredPremiumIncrease !== undefined &&
        state.civilLiability !== undefined &&
        state.respondent !== undefined &&
        state.tailCoverage !== undefined &&
        Object.keys(state.errors).length === 0
    const handleSubmit = () => {
        const {errors: _, ...body} = state;
        onSubmit(body)
    }

    const getError = (str: string) => {
        if (Object.keys(state.errors).includes(str)) return {content: state.errors[str][0], show: false}
        return undefined
    };


    React.useEffect(() => {
        setState(s => ({...s, valid: checks.length === 5}))
    }, [checks])


    return (
        <VStack align={'end'} w={'100%'} fontSize={'sm'} spacing={4}>
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_already_insured_label')}
                onChange={handleFieldChange('alreadyInsured', false, 'alreadyInsuredInsurer')}
                required
                value={user?.insurance ? user.insurance.alreadyInsured : state.alreadyInsured}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_already_insured_insurer_label')}{!!state.alreadyInsured && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('alreadyInsuredInsurer', !!state.alreadyInsured)}
                    error={getError('alreadyInsuredInsurer')}
                    value={user?.insurance ? user.insurance.alreadyInsuredInsurer : state.alreadyInsuredInsurer}
                />
            </Flex>
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_already_insured_refused_renew_label')}
                onChange={handleFieldChange('alreadyInsuredRefusedRenew', false, 'alreadyInsuredRefusedRenewReason')}
                required
                value={user?.insurance ? user.insurance.alreadyInsuredRefusedRenew : state.alreadyInsuredRefusedRenew}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_already_insured_refused_renew_reason_label')}{!!state.alreadyInsuredRefusedRenew && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('alreadyInsuredRefusedRenewReason', !!state.alreadyInsuredRefusedRenew)}
                    error={getError('alreadyInsuredRefusedRenewReason')}
                    value={user?.insurance ? user.insurance.alreadyInsuredRefusedRenewReason : state.alreadyInsuredRefusedRenewReason}
                />
            </Flex>
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_already_insured_cancellation_label')}
                onChange={handleFieldChange('alreadyInsuredCancellation', false, 'alreadyInsuredCancellationReason')}
                required
                value={user?.insurance ? user.insurance.alreadyInsuredCancellation : state.alreadyInsuredCancellation}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_already_insured_cancellation_reason_label')}{!!state.alreadyInsuredCancellation && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('alreadyInsuredCancellationReason', !!state.alreadyInsuredCancellation)}
                    error={getError('alreadyInsuredCancellationReason')}
                    value={user?.insurance ? user.insurance.alreadyInsuredCancellationReason : state.alreadyInsuredCancellationReason}
                />
            </Flex>
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_already_insured_premium_increase_label')}
                onChange={handleFieldChange('alreadyInsuredPremiumIncrease', false, 'alreadyInsuredPremiumIncreaseReason')}
                required
                value={user?.insurance ? user.insurance.alreadyInsuredPremiumIncrease : state.alreadyInsuredPremiumIncrease}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_already_insured_premium_increase_reason_label')}{!!state.alreadyInsuredPremiumIncrease && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('alreadyInsuredPremiumIncreaseReason', !!state.alreadyInsuredPremiumIncrease)}
                    error={getError('alreadyInsuredPremiumIncreaseReason')}
                    value={user?.insurance ? user.insurance.alreadyInsuredPremiumIncreaseReason : state.alreadyInsuredPremiumIncreaseReason}
                />
            </Flex>
            <Divider borderColor={'gray.200'} />
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_respondent_label')}
                onChange={handleFieldChange('respondent')}
                required
                value={user?.insurance ? user.insurance.respondent : state.respondent}
            />
            <Divider borderColor={'gray.200'} />
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_civil_liability_label')}
                onChange={handleFieldChange('civilLiability', false, 'civilLiabilityReason')}
                required
                value={user?.insurance ? user.insurance.civilLiability : state.civilLiability}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_civil_liability_reason_label')}{!!state.civilLiability && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('civilLiabilityReason', !!state.civilLiability)}
                    error={getError('civilLiabilityReason')}
                    value={user?.insurance ? user.insurance.civilLiabilityReason : state.civilLiabilityReason}
                />
            </Flex>
            <Divider borderColor={'gray.200'} />
            <ChooseField
                options={options}
                isDisabled={!!user?.insurance}
                label={t('field_tail_coverage_label')}
                onChange={handleFieldChange('tailCoverage', false, 'tailCoverageReason')}
                required
                value={user?.insurance ? user.insurance.tailCoverage : state.tailCoverage}
            />
            <Flex align={'center'} w={'100%'}>
                <Text pl={8} w={'70%'}>{t('field_tail_coverage_reason_label')}{!!state.tailCoverage && " *"}</Text>
                <OptionalField
                    isDisabled={!!user?.insurance}
                    onChange={handleFieldChange('tailCoverageReason', !!state.tailCoverage)}
                    error={getError('tailCoverageReason')}
                    value={user?.insurance ? user.insurance.tailCoverageReason : state.tailCoverageReason}
                />
            </Flex>
            <Divider borderColor={'gray.200'}/>
                <VStack align={'start'} w={'100%'}>
                <Flex w={'100%'}>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={!!user?.insurance || !!checks.find((v) => v === 1)}
                        isDisabled={!!user?.insurance}
                        onChange={handleCheck(1)}
                    />
                    <Text ml={4} fontSize={'xs'}>{profileT('profile_insurance_become_member_checkbox')}</Text>
                </Flex>
                <Flex w={'100%'}>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={!!user?.insurance || !!checks.find((v) => v === 2)}
                        isDisabled={!!user?.insurance}
                        onChange={handleCheck(2)}
                    />
                    <Text ml={4} fontSize={'xs'}>{profileT('profile_insurance_update_subscription_checkbox')}</Text>
                </Flex>
                <Flex w={'100%'}>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={!!user?.insurance || !!checks.find((v) => v === 3)}
                        isDisabled={!!user?.insurance}
                        onChange={handleCheck(3)}
                    />
                    <Text ml={4} fontSize={'xs'}>{profileT('profile_insurance_certificate_checkbox')}</Text>
                </Flex>
                <Flex w={'100%'}>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={!!user?.insurance || !!checks.find((v) => v === 4)}
                        isDisabled={!!user?.insurance}
                        onChange={handleCheck(4)}
                    />
                    <Text ml={4} fontSize={'xs'}>{profileT('profile_insurance_only_france_checkbox')}</Text>
                </Flex>
                <Flex w={'100%'}>
                    <Checkbox
                        colorScheme={'green'}
                        isChecked={!!user?.insurance || !!checks.find((v) => v === 5)}
                        isDisabled={!!user?.insurance}
                        onChange={handleCheck(5)}
                    />
                    <Text ml={4} fontSize={'xs'}>{profileT('profile_insurance_professional_checkbox')}</Text>
                </Flex>
            </VStack>
            <Box w={'100%'} fontSize={'2xs'}>
                <Text as={'span'} fontWeight={'semibold'}>
                    {profileT('profile_insurance_legal_notice_title').toUpperCase() + ' : '}
                </Text>
                <Text as={'span'}>{profileT('profile_insurance_legal_notice_description')}</Text>
                <UnorderedList pl={4}>
                    <ListItem>{profileT('profile_insurance_legal_notice_first')}</ListItem>
                    <ListItem>{profileT('profile_insurance_legal_notice_second')}</ListItem>
                    <ListItem>{profileT('profile_insurance_legal_notice_third')}</ListItem>
                    <ListItem>{profileT('profile_insurance_legal_notice_fourth')}</ListItem>
                    <ListItem>{profileT('profile_insurance_legal_notice_fifth')}</ListItem>
                </UnorderedList>
            </Box>
            {!user?.insurance && <Button isDisabled={!canSubmit} onClick={handleSubmit}>{t('button_subscribe_label')}</Button>}
        </VStack>
    )
}

export interface InsuranceFormState {
    alreadyInsured?: boolean;
    alreadyInsuredInsurer?: string;
    alreadyInsuredRefusedRenew?: boolean;
    alreadyInsuredRefusedRenewReason?: string;
    alreadyInsuredCancellation?: boolean;
    alreadyInsuredCancellationReason?: string;
    alreadyInsuredPremiumIncrease?: boolean;
    alreadyInsuredPremiumIncreaseReason?: string;
    respondent?: boolean;
    civilLiability?: boolean;
    civilLiabilityReason?: string;
    tailCoverage?: boolean;
    tailCoverageReason?: string;
    valid: boolean;
    errors: any;
}

export interface InsuranceFormProps extends asForm, withUser {}

export default InsuranceForm