import {
    Box,
    Button as ChakraButton,
    Flex,
    Heading,
    Icon, Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AiFillCreditCard, AiFillPrinter} from "react-icons/ai";
import Button from "../Button";
import {withProductsRequired, withUserRequired} from "../../config/withs";
import {fetchSubscriptionBill} from "../../services/documents";
import {generateParameters, generateSignature} from "../../services/systempay";


export function Subscriptions({products, user}: SubscriptionsProps) {
    const {t, i18n} = useTranslation('profile')
    const toast = useToast()
    const product = products.find(({name}) => name.toLowerCase().includes("cotisation"));
    const subscriptions = user.subscriptions?.filter(({product: {id}}) => product?.id === id)

    if (!product) return <Spinner color={"primary"} />

    const parameters = generateParameters(user, product)
    const systempaySignature = generateSignature(process.env['REACT_APP_SYSTEMPAY_SELLER_CERTIFICATE'] as string, parameters)

    return (
        <VStack w={'100%'} spacing={8}>
            <Heading fontSize={'md'} textColor={'primary'}>{t('profile_subscription_heading')}</Heading>
            <TableContainer w={'100%'}>
                <Table size={'sm'} variant={'simple'}>
                    <Thead>
                        <Tr>
                            <Th>{t('profile_subscription_table_head_created_at')}</Th>
                            <Th>{t('profile_subscription_table_head_payment_mean')}</Th>
                            <Th>{t('profile_subscription_table_head_start_date')}</Th>
                            <Th>{t('profile_subscription_table_head_end_date')}</Th>
                            <Th>{t('profile_subscription_table_head_prints')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions?.sort(
                            (s1, s2) => s1.createdAt >= s2.createdAt ? 1 : -1
                        ).map(({
                            endDate,
                            id,
                            paymentMean,
                            startDate,
                            createdAt
                        }) => {
                            const handleDownload = async () => {
                                const toastId = toast({status: "loading", description: t('profile_toast_loading_document_title')})
                                const file = await fetchSubscriptionBill(id);
                                if (!file) {
                                    toast.update(toastId,{
                                        status: "error", description: t('profile_toast_error_no_document'), isClosable: true
                                    })
                                } else {
                                    toast.close(toastId)
                                    const aElement = document.createElement('a');
                                        aElement.setAttribute(
                                            'download',
                                            `${t("profile_document_receipt")} ${user?.firstName} ${user?.lastName}.pdf`
                                        )
                                        aElement.href = file;
                                        aElement.setAttribute('target', '_blank');
                                        aElement.click();
                                        URL.revokeObjectURL(file);
                                }
                            }
                            return (
                                <Tr key={`subscription-${id}`}>
                                    <Td>{new Date(createdAt).toLocaleDateString(i18n.language)}</Td>
                                    <Td>{paymentMean}</Td>
                                    <Td>{startDate ? new Date(startDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                    <Td>{endDate ? new Date(endDate).toLocaleDateString(i18n.language) : "---"}</Td>
                                    <Td>
                                        <ChakraButton onClick={handleDownload} variant={'unstyled'}>
                                            <Flex align={'center'} textColor={'primary'}>
                                                <Icon as={AiFillPrinter} mr={1} />
                                                <Text _hover={{textDecoration: 'underline'}}>
                                                    {t('profile_subscription_table_prints_link')}
                                                </Text>
                                            </Flex>
                                        </ChakraButton>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <VStack w={'100%'}>
                <Text textColor={'primary'} w={'100%'}>
                    {t('profile_subscription_price', {amount: product?.price})}
                </Text>
                <Flex justify={'start'} w={'100%'}>
                    <VStack border={'1px'} borderColor={'gray.200'} p={4} rounded={'sm'} spacing={4}>
                        <Text as={'span'}>{t('profile_subscription_title')}</Text>
                        <Box w={'100%'}>
                            <Text fontSize={'xs'}>- {t('profile_subscription_instant_transaction')}</Text>
                            <Text fontSize={'xs'}>- {t('profile_subscription_secured_transaction')}</Text>
                        </Box>
                        <form method={'POST'} action={'https://paiement.systempay.fr/vads-payment/'}>
                            {Object.keys(parameters).map((key) => (
                                <input key={`sp-${key}`} type={"hidden"} name={key} value={(parameters as any)[key]} />
                            ))}
                            <input type={"hidden"} name={"signature"} value={systempaySignature} />
                            <Button leftIcon={<AiFillCreditCard />} type={"submit"}>
                                {t('profile_button_pay_cotisation')}
                            </Button>
                        </form>
                    </VStack>
                </Flex>
            </VStack>
        </VStack>
    )
}

export interface SubscriptionsProps extends withProductsRequired, withUserRequired {}

export default Subscriptions