export function addError(obj: any, key: string, value: string): any {
    if (Object.keys(obj).includes(key) && !obj[key].includes(value)) {
        obj[key].append(value)
    } else if (!Object.keys(obj).includes(key)) {
        obj[key] = [value]
    }
    return obj;
}

export function removeError(obj: any, key: string, value: string): any {
    if (Object.keys(obj).includes(key) && obj[key].includes(value)) {
        const index = obj[key].indexOf(value);
        if (index > -1) {
            obj[key].splice(index, 1);
        }
    }
    if (Object.keys(obj).includes(key) && obj[key].length === 0) {
        delete obj[key];
    }
    return obj;
}