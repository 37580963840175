import {snakeToCamel} from "../utils/cases";

export async function createPasswordReset(email: string) {
    const resp = await fetch(`${process.env['REACT_APP_API_URL']}/password-reset/`, {
         method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({email}),
    })
    if (resp.status !== 204) {
           return await resp.json()
    } else {
       return null
   }
}

export async function fetchPasswordReset(code: string) {
    const resp = await fetch(`${process.env['REACT_APP_API_URL']}/password-reset/${code}`)
    if (resp.ok) {
        const data = await resp.json()
        return snakeToCamel(data)
    } else {
        return null
    }
}