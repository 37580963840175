import {Product} from "../config/types";
import {snakeToCamel} from "../utils/cases";

export async function listProducts(): Promise<Product[]> {
    try {
        const resp = await fetch(`${process.env['REACT_APP_API_URL']}/products/`)
        return snakeToCamel(await resp.json()).results as Product[];
    } catch {
        return []
    }
}

export async function getCertisophroProducts(): Promise<Product[]> {
    try {
        const products = await listProducts();
        return products.filter(({name}) => name.toLowerCase().includes("certisophro"))
    } catch {
        return [];
    }
}

export async function getInsuranceProduct(): Promise<Product | undefined> {
    try {
        const products = await listProducts();
        return products.find(({name}) => name.toLowerCase().includes("assurance"))
    } catch {
        return undefined;
    }
}

export async function getSubscriptionProduct(): Promise<Product | undefined> {
    try {
        const products = await listProducts();
        return products.find(({name}) => name.toLowerCase().includes("cotisation"));
    } catch {
        return undefined;
    }
}