import {Box, Center, Divider, StackDivider, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export function Navbar({admin = false}: NavbarProps) {
    const {t} = useTranslation('common')
    const items = [
        {name: 'profile', url: '/profile'},
        {name: 'book', url: '/book'},
    ]
    const isCurrent = (str: string) => {
        const splits = window?.location?.href?.split('/')
        return splits?.length ? splits[splits?.length - 1] === str : false
    }
    const isLast = (id: Number) => id === items.length - 1;

    return (
        <VStack direction={'column'} spacing={0} divider={<StackDivider borderColor={'gray.200'} />} w={'100%'}>
            {items.map((item, index) => (
                <Box key={`navbar-${item.name}`} h={'100%'} w={'100%'}>
                    <a href={item.url}>
                        <Center
                            backgroundColor={isCurrent(item.name) ? 'primary' : 'none'}
                            borderBottomRadius={isLast(index) ? 'md' : 'none'}
                            borderTopRadius={index === 0 ? 'md' : 'none'}
                            cursor={isCurrent(item.name) ? 'initial' : 'pointer'}
                            textColor={isCurrent(item.name) ? 'white' : 'black'}
                            p={4}
                            w={'100%'}
                            _hover={{
                                backgroundColor: isCurrent(item.name) ? 'primary' : 'gray.50'
                            }}
                        >
                    {t(`navbar_${item.name}_label`)}
                        </Center>
                    </a>
                    {index !== items.length -1 && index !== 0 && <Divider borderColor={'gray.200'} />}
                </Box>
            ))}
        </VStack>
    )
}

export interface NavbarProps {
    admin?: boolean
}

export default Navbar