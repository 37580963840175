import * as React from "react";
import {School} from "../config/types";
import {fetchSchools} from "../services/school";

export function useSchools() {
    const [schools, setSchools] = React.useState<School[] | undefined>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        fetchSchools().then((data) => {
            if (data) {
                setSchools(data)
            }
            setLoading(false)
        }).catch(() => {
            setError('error_no_password_reset')
            setLoading(false)
        })
    }, [])

    return {schools, loading, error}
}

export default useSchools