import * as React from "react";
import {Navigate} from "react-router-dom"
import PaymentStatusPage, {paymentStatusPageLoader}  from "../pages/PaymentStatusPage";
import ProfilePage, {profilePageLoader} from "../pages/ProfilePage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import PasswordResetPage, {passwordResetPageLoader} from "../pages/PasswordResetPage";
import CssDataPage, {cssDataPageLoader} from "../pages/CssDataPage";
import BookPage from "../pages/BookPage";
import EmailResetPage from "../pages/EmailResetPage";


export const routes = [
    {
        path: "/profile",
        element: <ProfilePage />,
        loader: profilePageLoader,
    },
    {
        path: "/:lang/profile",
        element: <ProfilePage />,
        loader: profilePageLoader,
    },
    {
        path: "/book",
        element: <BookPage />,
    },
    {
        path: "/auth/login",
        element: <LoginPage />,
    },
    {
        path: "/auth/register",
        element: <RegisterPage />,
    },
    {
        path: "/auth/password-reset",
        element: <PasswordResetPage />,
        loader: passwordResetPageLoader,
    },
    {
        path: "/auth/email-reset",
        element: <EmailResetPage />,
    },
    {
        path: "/payment/:status",
        element: <PaymentStatusPage />,
        loader: paymentStatusPageLoader,
    },
    {
        path: "/css/data",
        element: <CssDataPage />,
        loader: cssDataPageLoader
    },
    {
        path: "/",
        element: <Navigate replace to={"/profile"} />
    }
]